body {
  &.checkout-onepage-success {
    overflow-x: hidden;

    .page-main {
      max-width: 1920px;

      .columns {
        .column {
          .amtypage-page {
            position: relative;
            margin-bottom: 20px;
            padding-top: 50px;
            padding-bottom: 20px;
            background: $blue;

            + br {
              display: none;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: -2000px;
              width: 2000px;
              height: 100%;
              background: $blue;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: -2000px;
              width: 2000px;
              height: 100%;
              background: $blue;
            }

            h2 {
              margin-top: 0;
              margin-bottom: 0;
              padding-bottom: 10px;
              color: white;
            }

            p {
              margin-top: 0;
              margin-bottom: 0;
              padding-bottom: 10px;
              color: white;
            }
          }

          .amtyblock-order-review {
            .checkout-success {
              .success-messages {
                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: -2000px;
                  width: 2000px;
                  height: 100%;
                  background: $blue;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: -2000px;
                  width: 2000px;
                  height: 100%;
                  background: $blue;
                }

                position: relative;
                padding-bottom: 80px;
                background: $blue;

                p {
                  color: white;
                }

                a {
                  color: $orange;
                }
              }

              .account {
                .column {
                  display: flex;
                  gap: 20px;
                  flex-wrap: wrap;

                  @include mobile {
                    flex-direction: column;
                  }

                  .block-order-details {
                    width: 100%;

                    .block-title {
                      margin-bottom: 0;

                      strong {
                        font-weight: 700;
                      }
                    }

                    .order-date {
                      padding-bottom: 0;
                      border-bottom: 0;
                    }
                  }

                  .block-order-details-view {
                    flex: 1;
                    min-width: 400px;
                    padding: 20px;
                    border: 1px solid $grey;
                    background: white;

                    @include mobile {
                      width: 100%;
                      min-width: 0;
                    }

                    @include tabletportrait {
                      width: 100%;
                      min-width: 0;
                    }

                    .block-content {
                      .box {
                        @include tabletportrait {
                          width: 100%;
                        }

                        .payment-method {
                          table {
                            th,
                            td {
                              padding: 0;
                            }
                          }
                        }
                      }
                    }
                  }

                  .order-details-items {
                    flex: 1;
                    min-width: 400px;
                    padding: 20px;
                    border: 1px solid $grey;
                    background: white;

                    @include mobile {
                      min-width: 0;
                    }

                    .order-title {
                      strong {
                        font-weight: 700;
                      }
                    }

                    .order-items {
                      .table-order-items {
                        tr {
                          td,
                          th {
                            span {
                              font-size: 14px;
                              line-height: 19px;
                            }

                            &:first-child {
                              padding-left: 0;
                            }

                            &:last-child {
                              padding-right: 0;
                            }
                          }
                        }

                        @include media(mobile, tabletportrait, tabletlandscape) {
                          thead {
                            tr {
                              th {
                                display: none;
                              }
                            }
                          }

                          tbody {
                            tr {
                              td {
                                &[data-th]::before {
                                  content: attr(data-th) ": ";
                                  display: inline-block;
                                  padding-right: 10px;
                                  color: $black;
                                  font-weight: 700;
                                }
                              }
                            }

                            tr {
                              display: block;

                              td {
                                display: block;
                                padding: 5px 0;
                                border-bottom: none;
                                text-align: left;

                                &:first-child,
                                &:last-child {
                                  padding-top: 20px;
                                }

                                span,
                                .items-qty {
                                  display: inline-block;
                                }
                              }
                            }
                          }

                          tfoot {
                            tr {
                              td,
                              th {
                                display: table-cell;
                              }

                              td {
                                width: 30%;
                              }

                              th {
                                width: 70%;
                                text-align: left;
                              }

                              &:first-child {
                                td,
                                th {
                                  padding-top: 20px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
