@keyframes movebg {
  0% { background-position: center top; }
  50% { background-position: center -60px; }
  100% { background-position: center top; }
}

@keyframes movebgrev {
  0% { background-position: center -60px; }
  50% { background-position: center top; }
  100% { background-position: center -60px; }
}

body {
  &.cms-home {
    overflow-x: hidden;
    background: white;

    #maincontent {
      .columns {
        .column {
          [data-content-type="row"][data-appearance="contained"] {
            max-width: 1440px;
          }

          .hero {
            padding: 0 30px;

            @include mobile {
              margin-top: 0;
              padding: 0;
            }

            .pagebuilder-slide-wrapper {
              width: 100%;
              background-size: cover;
              aspect-ratio: 1 / 0.25;

              @include mobile {
                aspect-ratio: 1 / 1;
              }
            }

            .pagebuilder-slider {
              padding: 0;
              overflow: hidden;
              border-radius: 30px;

              @include mobile {
                border-radius: 0;
              }
            }

            .slick-slider {
              .slick-arrow {
                top: auto;
                bottom: 15px;
                left: auto;
                width: 30px;
                height: 30px;
                border-radius: 20px;
                background: white !important;

                @include mobile {
                  display: none !important;
                }

                &::before {
                  padding: 0;
                  font-size: 7px;
                }

                &.slick-next {
                  right: 35px;
                }

                &.slick-prev {
                  right: 75px;
                }
              }

              .slick-dots {
                bottom: 15px;

                li {
                  width: auto;
                  height: 10px;

                  button {
                    width: 10px;
                    height: 10px;
                    border: 0;
                    background: white;
                  }

                  &.slick-active {
                    button {
                      width: 30px;
                      height: 10px;
                    }
                  }
                }
              }
            }
          }

          .row-full-width-inner {
            max-width: 1440px;
          }

          .spotlights {
            padding: 0 30px;

            @include mobile {
              padding: 0 15px;
            }

            .pagebuilder-column-line {
              gap: 30px;

              @include mobile {
                gap: 15px;
              }
            }

            .pagebuilder-column-group {
              .pagebuilder-column {
                figure {
                  overflow: hidden;
                  border-radius: 30px;

                  @include mobile {
                    border-radius: 15px;
                  }
                }
              }
            }
          }

          .product-items {
            padding: 0 15px;

            @include mobile {
              padding: 0;
            }
          }

          .home-highlights {
            margin: 30px 0;
            padding: 0 30px;

            @include mobile {
              padding: 0 15px;
            }

            .row-full-width-inner {
              .pagebuilder-column-group {
                gap: 30px;

                > div {
                  min-height: 500px;
                }
              }
            }
          }

          .title {
            h1,
            h2,
            h3,
            h4,
            p {
              max-width: 600px;
              margin-right: auto;
              margin-left: auto;
              padding: 0 30px;

              @include mobile {
                padding: 0 15px;
              }
            }
          }

          #homepage-payment {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 0;
            gap: 30px;

            @include mobile {
              flex-flow: row wrap;
              gap: 0;
            }

            li {
              list-style: none;

              @include mobile {
                flex: auto;
                width: 33.33%;
                text-align: center;
              }

              a {
                display: flex;
                align-items: center;
                gap: 10px;
                color: $black;
              }

              img {
                width: auto;
                height: 100%;
                max-height: 40px;
                transition: all 0.5s ease;
                opacity: 0.3;
                mix-blend-mode: darken;
                filter: saturate(0);

                @include mobile {
                  width: auto;
                  height: auto;
                  max-height: 40px;
                }
              }

              p {
                margin: 0;
                font-size: 14px;
              }

              &:hover {
                img {
                  opacity: 1;
                  filter: saturate(1);
                }
              }
            }
          }

          .home-categories {
            .pagebuilder-column-group {
              padding: 0 30px;

              @include mobile {
                padding: 0;
              }

              .pagebuilder-column-line {
                @include mobile {
                  flex-wrap: nowrap;
                }
              }

              .pagebuilder-column {
                width: 200px;

                @include mobile {
                  width: 150px;
                  min-width: 150px;
                }

                a {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 10px;

                  img {
                    transform: scale(1);
                    transition: 300ms ease-in-out transform;
                    will-change: transform;
                  }

                  h3 {
                    color: $black;
                    font-size: 18px;

                    @include mobile {
                      font-size: 15px;
                      text-align: center;
                    }
                  }

                  &:hover {
                    text-decoration: none;

                    h3 {
                      text-decoration: none;
                    }

                    img {
                      transform: scale(1.05);
                    }
                  }
                }
              }

              .slick-slider {
                @include mobile {
                  padding: 0;
                }

                .slick-arrow {
                  width: 30px;
                  height: 30px;
                  border-radius: 15px;
                  background: $lightgrey !important;

                  @include mobile {
                    display: none !important;
                  }

                  &.slick-next {
                    right: 0;
                  }

                  &.slick-prev {
                    left: 0;
                  }

                  &::before {
                    padding: 0;
                    color: $black;
                    font-size: 7px;
                  }
                }
              }

              .slick-list {
                width: 100%;

                .slick-track {
                  display: flex;
                  padding: 0;
                  gap: 10px;

                  @include mobile {
                    gap: 0;
                  }

                  .slick-slide {
                    @include mobile {
                      width: 40vw !important;
                    }
                  }
                }
              }
            }
          }

          .recently-viewed {
            padding: 0 15px;

            @include mobile {
              padding: 0;
            }

            .row-full-width-inner {
              display: flex;
              flex-direction: row;
              overflow: hidden;
              gap: 30px;

              @include mobile {
                flex-direction: column;
              }

              .recent,
              .trending {
                flex: 1;
                min-width: 0;

                @include mobile {
                  max-width: 100%;
                }

                .product-recs {
                  height: 100%;

                  .recommendations {
                    height: 100%;

                    .block-static-block {
                      height: 100%;

                      .block-products-list {
                        height: 100%;
                        padding: 10px;

                        .products.wrapper {
                          margin: 0;

                          ol.products {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0;

                            .slick-arrow {
                              display: flex !important;
                              top: -30px;
                              width: 30px;
                              height: 30px;
                              border-radius: 15px;
                              background: $lightgrey !important;

                              &::before {
                                padding: 0;
                                font-size: 7px;
                              }

                              &.slick-next {
                                right: 5px;
                              }

                              &.slick-prev {
                                right: 45px;
                                left: auto;
                              }
                            }

                            .slick-list {
                              margin-bottom: 0;
                              padding: 0;

                              li {
                                width: 120px;

                                .product-item-info {
                                  outline: none;

                                  .product-item-photo {
                                    img {
                                      aspect-ratio: 1/1;
                                      object-fit: contain;
                                    }
                                  }

                                  .product-item-inner {
                                    display: none;
                                  }

                                  .product-item-details {
                                    .price-box {

                                    }

                                    .product-item-name {
                                      min-height: 0;

                                      .product-item-link {
                                        span {
                                          display: block;
                                          max-height: 60px;
                                          overflow: hidden;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .recent {
                min-width: 320px;
                max-width: 320px;

                &.hide {
                  display: none;
                }

                @include mobile {
                  width: 100%;
                  min-width: 0;
                  max-width: none;
                }

                .product-recs {
                  .recommendations {
                    .block-products-list {
                      padding: 10px;

                      @include mobile {
                        margin-right: 0;
                      }

                      .block-content {
                        &::before {
                          content: "Continue Shopping";
                          padding: 5px;
                          font-size: 21px;
                          font-weight: 700;
                        }

                        .products.wrapper {
                          margin: 0;

                          ol.products {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0;
                            gap: 0;

                            li {
                              width: calc(100% / 2);
                              min-width: auto;
                              padding: 5px;

                              @include mobile {
                                width: 50%;
                              }

                              &:nth-child(n+5) {
                                display: none;
                              }

                              .product-item-info {
                                padding: 0;
                                outline: none;

                                @include mobile {
                                  flex-direction: column;
                                }

                                .product-item-photo {
                                  img {
                                    width: 100%;
                                    max-width: 160px;
                                    aspect-ratio: 1/1;
                                    object-fit: contain;

                                    @include mobile {
                                      max-width: none;
                                    }
                                  }
                                }

                                .product-item-inner {
                                  display: none;
                                }

                                .product-item-details {
                                  @include mobile {
                                    margin-left: 0;
                                  }

                                  .price-box {
                                    display: none;
                                  }

                                  .product-item-name {
                                    min-height: 0;

                                    .product-item-link {
                                      span {
                                        display: block;
                                        max-height: 19px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .trending {
                flex: 3;

                .product-recs {
                  .recommendations {

                  }
                }

                .block-products-list {
                  .block-content {
                    &::before {
                      content: "Recommended for you";
                      padding: 5px 0 0 5px;
                      font-size: 21px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .recommendations-wrapper {
        .recommendations {
          max-width: 1440px;
          margin: 0 auto;

          .block-title {
            text-align: center;
          }
        }
      }
    }
  }
}
