body {
  &.open {
    overflow: hidden;

    .exit-search {
      span {
        transition: 0.25s ease-in-out;
        opacity: 0 !important;

        &:nth-child(1) {
          top: -2px !important;
          left: 2px !important;
          transform: rotate(45deg) !important;
        }

        &:nth-child(2) {
          width: 0% !important;
          opacity: 0 !important;
        }

        &:nth-child(3) {
          top: 12px !important;
          left: 2px !important;
          transform: rotate(-45deg) !important;
        }
      }
    }
  }

  .ammenu-button.-hamburger {
    display: none;
  }

  .ammenu-menu-wrapper {
    position: fixed;
    z-index: 999;
    top: 120px;
    width: 354px;
    max-width: 354px;
    height: calc(100% - 120px);
    transform: translateX(-400px);
    transition: 300ms ease-in-out transform;
    font-family: Nunito;

    .-sidebar {
      width: 100%;
    }

    .ammenu-icon-block.-toggle {
      width: 10px;
      height: 10px;

      svg {
        width: 10px;
        height: 10px;
      }
    }

    @include mobile {
      top: 140px;
    }

    &.open {
      transform: translateX(0);
    }

    .ammenu-nav-sections {
      &.-topmenu {
        height: 100%;

        .ammenu-main-container {
          height: 100%;
          padding: 0;

          .ammenu-items {
            display: flex;
            align-content: flex-start;
            height: 100%;
            overflow-y: auto;

            .ammenu-item {

            }
          }
        }

        .ammenu-items.-root {
          > .ammenu-item {
            width: 100%;

            a,
            .ammenu-link.-simple {
              min-height: 50px;
              padding-top: 5px;
              padding-bottom: 5px;
              padding-left: 20px;
              font-size: 16px;

              .ammenu-icon-block {
                &:not(.-toggle) {
                  align-self: flex-start;
                  width: 40px;
                  height: 40px;
                  background-color: transparent !important;
                }
              }

              p {
                font-weight: 700;
              }
            }
          }
        }

        .ammenu-submenu-wrapper {
          position: fixed;
          top: 0;
          right: auto !important;
          left: 354px !important;
          width: calc(100vw - 400px) !important;
          max-width: 1280px;
          height: 100%;
          max-height: none;
          padding: 0;
          overflow-y: auto;
          border-radius: 0;

          >.ammenu-submenu-block {
            >.ammenu-category-columns {
              flex-direction: row;
              width: 100%;
            }
          }

          .ammenu-category-columns {
            .ammenu-column {
              flex-direction: column;
              width: 354px;
              min-width: 250px;
              padding-bottom: 0;

              a {
                color: $black !important;
              }
            }
          }

          .ammenu-submenu-block {
            .ammenu-category-columns {
              .ammenu-category-tree {
                .ammenu-title {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  padding-bottom: 0;
                  color: $black !important;
                  font-size: 16px;
                  font-weight: 700;
                }

                .category-item {
                  .ammenu-title {

                  }
                }

                .ammenu-list {
                  padding-top: 0;
                  padding-left: 0;

                  .-all-link {
                    color: $brightblue !important;
                  }

                  li {
                    a,
                    button {
                      justify-content: flex-start;
                      height: auto;
                      min-height: 30px;
                      padding-top: 0;
                      padding-bottom: 0;
                      font-size: 14px;
                      font-weight: 500;

                      p {
                        span {
                          font-weight: 500;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.-mobile {
        .ammenu-menu-title {
          .ammenu-button {
            display: none;
          }
        }
      }
    }

    @include media(mobile, tabletportrait) {
      .ammenu-nav-sections {
        .ammenu-menu-title {
          display: none;
        }

        .ammenu-tabs-list {
          margin: 0;
          padding: 0;
          border-radius: 0;

          li {
            flex: 1;
            height: 50px;

            button {
              height: 50px;
              border-radius: 0;

              &:first-child {
                border-right: 1px solid $grey;
              }

              &:not(.-active) {
                border-bottom: 1px solid $grey;
                background: $lightgrey !important;
              }

              &.-active {
                background-color: white !important;
              }
            }
          }
        }

        .ammenu-drill-section {
          padding-top: 15px;

          .ammenu-drill-navigation {
            padding: 10px 0;

            .ammenu-button.-go-main,
            .ammenu-button.-prev {
              justify-content: flex-start;
            }
          }

          .ammenu-current-title {
            figure {
              width: 30px;
              height: 30px;
              margin-top: 0;
              background-color: transparent !important;
            }
          }

          .ammenu-items {
            .ammenu-item {
              .ammenu-text-block {
                .ammenu-text {
                  max-width: 215px;
                  text-wrap: nowrap;
                  white-space: nowrap;
                }
              }

              .ammenu-link {
                position: relative;
                justify-content: flex-start;
                border-bottom: 1px solid $grey !important;

                &::before {
                  content: "";
                  display: inline-flex;
                  display: none;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                  font-family: icomoon;
                }

                .ammenu-icon-block.-icon {
                  width: 30px;
                  height: 30px;
                  margin-top: 0;
                  background-color: transparent !important;

                  @include mobile {
                    width: 40px;
                    height: 40px;
                    margin-top: -10px;
                  }
                }

                .ammenu-icon-block.-toggle {
                  position: absolute;
                  top: 50%;
                  right: 15px;
                  margin: 0;
                  transform: translateY(-50%) rotate(180deg);
                  background: none !important;
                }
              }

              &.authorization-link {
                order: 99;
                margin-bottom: 10px;

                a {
                  &::before {
                    content: "\e907";
                  }
                }
              }

              &.track-order {
                a {
                  &::before {
                    content: "\e908";
                  }
                }
              }

              &.spare-parts {
                a {
                  &::before {
                    content: "\e991";
                  }
                }
              }

              &.my-orders {
                a {
                  &::before {
                    content: "\e905";
                  }
                }
              }

              &.warranty {
                a {
                  &::before {
                    content: "\e90c";
                  }
                }
              }

              &.help-centre {
                a {
                  &::before {
                    content: "\e909";
                  }
                }
              }

              &.my-account {
                a {
                  &::before {
                    content: "\e90d";
                  }
                }
              }

              &.create-account {
                a {
                  &::before {
                    content: "\e900";
                  }
                }
              }

              &.logout,
              &.login {
                a {
                  &::before {
                    content: "\e907";
                  }
                }
              }
            }
          }
        }

        .ammenu-account-section {
          height: auto;
          padding-top: 15px;

          .ammenu-menu-greetings {
            display: none;
            margin-bottom: 0;

            &.-logged {
              display: block;
            }
          }

          .ammenu-drill-section {
            .ammenu-items {
              li {
                a {
                  .ammenu-icon-block.-icon {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .hamburger {
    position: relative;

    &.open {
      #hamburger-background {
        content: "";
        position: fixed;
        z-index: 998;
        top: 0;
        top: 120px;
        left: 0;
        width: 100%;
        height: calc(100% - 120px);
        background: rgb(0 0 0 / 50%);

        @include mobile {
          top: 140px;
        }
      }

      #nav-icon span:nth-child(1) {
        top: -2px;
        left: 2px;
        transform: rotate(45deg);
      }

      #nav-icon span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      #nav-icon span:nth-child(3) {
        top: 12px;
        left: 2px;
        transform: rotate(-45deg);
      }
    }

    #nav-icon {
      position: relative;
      width: 20px;
      height: 13px;
      margin: 0 auto;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

    #nav-icon span {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      opacity: 1;
      background: white;
    }

    #nav-icon span:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    #nav-icon span:nth-child(2) {
      top: 5px;
      transform-origin: left center;
    }

    #nav-icon span:nth-child(3) {
      top: 10px;
      transform-origin: left center;
    }
  }

  &.minified {
    .ammenu-menu-wrapper {
      top: 70px;
      height: calc(100% - 70px);
    }

    .hamburger.open {
      #hamburger-background {
        top: 70px;
        height: calc(100% - 70px);
      }
    }

    @include mobile {
      .ammenu-menu-wrapper {
        top: 60px;
        height: calc(100% - 60px);
      }

      .hamburger.open {
        #hamburger-background {
          top: 60px;
          height: calc(100% - 60px);
        }
      }
    }
  }
}
