@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

body {
  font-family: Nunito, sans-serif;
  font-weight: 500;

  &.checkout-cart-index {
    padding-top: 120px !important;

    @include mobile {
      padding-top: 170px !important;
    }

    .page-main {
      .page-title-wrapper {
        padding-top: 30px;

        h1 {
          margin-bottom: 15px;
          font-size: 28px;
        }
      }

      .columns {
        .column {
          .cart-container {
            .form-cart {
              width: calc(100% - 360px);

              @include tabletportrait {
                width: 100%;
              }

              @include mobile {
                width: 100%;
                max-width: 400px;
                margin: 0 auto 40px;
                float: none;
              }

              .cart {
                &.table-wrapper {
                  border: 1px solid $grey;
                  background: white;

                  #shopping-cart-table {
                    thead {
                      tr {
                        border-bottom: 1px solid $grey;

                        th {
                          padding: 0;
                          padding: 15px;
                          font-size: 14px;
                          font-weight: 500;
                          line-height: 14px;

                          &.subtotal {
                            padding-right: 50px;
                          }
                        }
                      }

                      + .item {
                        border-top: 0;
                        border-bottom: 0;

                        &:last-child {
                          border-bottom: 0;
                        }
                      }
                    }

                    .item {
                      border-top: 0;
                      border-bottom: 0;

                      &.cart {
                        border-bottom: 1px solid $grey;

                        &:last-child {
                          border-bottom: 0;
                        }
                      }

                      .item-info {
                        td {
                          padding: 15px;
                          vertical-align: middle;

                          &.item {
                            display: inline-flex;

                            .product-item-photo {
                              position: relative;
                              top: 0;
                              width: auto;
                              min-width: 100px;
                              padding-right: 0;

                              .product-image-container {
                                min-width: 100px;

                                .product-image-wrapper {
                                  display: block;

                                  .product-image-photo {
                                    position: relative;
                                    min-width: 100px;
                                    max-width: 100px;
                                  }
                                }
                              }

                              @include mobile {
                                position: relative;
                              }
                            }

                            .product-item-details {
                              padding-left: 10px;
                              vertical-align: middle;

                              .product-item-name {
                                margin-top: 0;
                                padding-bottom: 50px;

                                @include mobile {
                                  padding-bottom: 0;
                                }
                              }

                              .ampreorder-note {
                                color: $red;
                              }
                            }
                          }

                          &.price {
                            span {
                              font-size: 14px;

                              &.cart-price {
                                display: flex;
                                flex-direction: column;
                              }

                              &.old-price {
                                color: $red;
                                font-size: 12px;
                                text-decoration: line-through;
                              }
                            }
                          }

                          &.qty {

                          }

                          &.subtotal {
                            padding-right: 50px;

                            span {
                              font-size: 14px;
                            }
                          }
                        }
                      }

                      .item-actions {
                        td {
                          padding: 0;

                          .actions-toolbar {
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            transform: translateY(-50%);

                            @include mobile {
                              position: relative;
                              inset: auto;
                              padding: 15px 0 30px;
                              transform: none;
                            }

                            .action {
                              padding: 0;
                              border: 0;
                              background: transparent;
                              text-decoration: underline;

                              &.action-towishlist {
                                display: none;
                              }

                              &.action-edit {
                                display: none;
                              }

                              &.action-delete {
                                margin: 0;
                                text-decoration: none;

                                @include mobile {
                                  text-decoration: underline;
                                }

                                &::before {
                                  content: "\e9ac";
                                  font-family: icomoon;
                                  font-size: 20px;

                                  @include mobile {
                                    display: none;
                                  }
                                }

                                &:hover {
                                  &::before {
                                    color: $red;
                                  }
                                }

                                span {
                                  display: none;

                                  @include mobile {
                                    display: inline;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.main {

                }
              }

              .cart.main.actions {
                .action {
                  &.continue,
                  &.update {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                  }
                }
              }
            }

            .cart-summary {
              width: 320px;
              padding: 0;
              border: 1px solid $grey;
              background: white;

              @include tabletportrait {
                top: auto !important;
                width: 100%;
                max-width: 400px;
                margin: 0 auto 40px;
                float: none;
              }

              @include mobile {
                width: 100%;
                max-width: 400px;
                margin: 0 auto 40px;
                float: none;
              }

              > .title {
                display: none;
              }

              .block {
                padding: 0 15px;

                .title {
                  padding: 0;
                  padding-top: 15px;
                  border: 0;
                  cursor: initial;

                  strong {
                    font-weight: 700;
                  }

                  &::after {
                    display: none;
                  }
                }
              }

              .shipping {
                .content {
                  display: block !important;

                  form {
                    &#co-shipping-method-form {
                      .fieldset {
                        .items {
                          .item-options {
                            .description {
                              display: none;
                            }

                            .field.choice.item {
                              margin: 0 0 10px;
                              padding: 0;

                              input.radio {
                                margin-left: 0;
                              }

                              label.label {
                                display: flex;
                                top: 2px;
                                left: auto;
                                flex: 1;
                                justify-content: space-between;
                                margin-left: 5px;

                                .price {
                                  display: flex;
                                  align-items: center;
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    .fieldset {
                      margin: 0;

                      dl {
                        .item-title {
                          display: none;
                        }
                      }

                      .field {
                        &[name*="country"],
                        &[name*="region"] {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }

              .discount,
              .giftcard {
                display: none;
              }

              .cart-totals {
                padding: 15px 15px 10px;
                background: $blue;

                .table-wrapper {
                  .loading-mask {
                    background: transparent;
                  }

                  table.totals {
                    tr {
                      .mark,
                      .amount {
                        padding: 0;
                      }

                      .mark {
                        color: white;
                      }

                      .amount {
                        color: white;
                      }

                      &.grand.totals {
                        .amount {
                          .price {
                            font-size: 18px;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .zip-widget__wrapper {
                display: none;
              }

              .checkout-methods-items {
                margin: 0;
                padding: 15px;

                li {
                  &.item {
                    margin: 0;
                  }
                }

                button.checkout {
                  display: flex;
                  position: relative;
                  height: 50px;
                  overflow: hidden;
                  transition: 300ms ease text-indent;
                  border: 0;
                  background: $orange;

                  &::after {
                    content: "\e901";
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 100%;
                    transform: translateX(50px);
                    transition: 300ms ease-in-out transform;
                    background: $blue;
                    font-family: icomoon;
                    font-size: 24px;
                    text-indent: 0;
                  }

                  &:hover {
                    text-indent: -50px;

                    &::after {
                      transform: none;
                    }
                  }
                }

                .cart-payment-methods {
                  ul {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    padding: 10px 0 0;
                    gap: 5px;

                    li {
                      display: flex;
                      align-self: center;
                      margin-bottom: 0;
                      list-style: none;
                    }
                  }
                }

                .latitudepayments-popup {
                  display: none;
                }
              }
            }

            #gift-options-cart {
              display: none;
            }
          }

          .ampromo-items-add {
            width: 100%;
            margin-top: 0;
            margin-bottom: 15px;
            border: 1px solid $green;
            background: $lightgreen;

            @include mobile {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }

    .ampromo-overlay {
      .ampromo-popup-wrapper {
        top: 50%;
        width: auto;
        margin: 0 auto;
        padding: 15px;
        transform: translateY(-50%);
        background: $lightgrey;

        h2 {
          margin-top: 0;
          font-size: 21px;
        }

        .ampromo-popup-container {
          .ampromo-carousel-product {
            .ampromo-gallery {
              margin: 0;
              padding: 0;

              .ampromo-item {
                background: white;

                &:hover {
                  border: 1px solid $grey;
                  background: white;
                }

                .ampromo-items-form {
                  background: transparent;

                  .ampromo-product-select {
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    input {
                      border: none;
                    }
                  }

                  .ampromo-item-title {
                    .ampromo-title {
                      max-height: 30px;
                      margin: 0;
                      overflow: hidden;

                      a {
                        color: $black;
                        font-weight: 500;
                      }
                    }
                  }

                  .ampromo-options {
                    display: none;
                  }

                  .product-info-price {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;

                    .price-base-price {
                      .price {
                        margin: 0;
                        margin-left: 10px;
                        color: $red;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 16px;
                        text-decoration: line-through;
                      }
                    }

                    .price-new-price {
                      .price-wrapper {
                        font-size: 16px;
                        line-height: 100%;

                        .price {
                          display: block;
                          height: 24px;
                          font-size: 0;
                          font-weight: 800;
                          line-height: 24px;

                          &::before {
                            content: "FREE";
                            font-size: 21px;
                            line-height: 24px;
                          }
                        }
                      }
                    }
                  }

                  .ampromo-item-qty-input {
                    .ampromo-qty {
                      max-width: 30px;
                      margin-right: 5px;
                    }
                  }

                  .ampromo-item-buttons {
                    .action {
                      &.tocart {
                        position: relative;
                        width: 100%;
                        height: 50px;
                        margin: 0;
                        overflow: hidden;
                        transition: 300ms ease-in-out all;
                        border: 0;
                        border-radius: 0;
                        border-radius: 3px;
                        background: $brightblue;
                        color: white;
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: none;

                        &::after {
                          content: "\e901";
                          display: flex;
                          position: absolute;
                          top: 0;
                          right: 0;
                          align-items: center;
                          justify-content: center;
                          width: 50px;
                          height: 100%;
                          transform: translateX(50px);
                          transition: 300ms ease-in-out transform;
                          background: #121011;
                          font-family: icomoon;
                          font-size: 24px;
                          text-indent: 0;
                        }

                        &:hover {
                          &::after {
                            transform: none;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .slick-arrow {
                display: none !important;
              }

              .slick-dots {
                margin-top: 10px;

                li {
                  button {
                    &::before {
                      content: "";
                      width: 15px;
                      height: 15px;
                      border-radius: 8px;
                      opacity: 0.3;
                      background: $black;
                    }
                  }

                  &.slick-active {
                    button {
                      &::before {
                        opacity: 1;
                      }
                    }
                  }
                }
              }

              .slick-list {
                .slick-track {
                  display: flex;
                  margin: 0;
                }
              }
            }

            .ampromo-item-buttons {
              .ampromo-button {
                position: relative;
                width: 100%;
                max-width: 240px;
                height: 50px;
                margin: 0;
                margin-top: 10px;
                overflow: hidden;
                transition: 300ms ease-in-out all;
                border: 0;
                border-radius: 0;
                border-radius: 3px;
                background: $brightblue;
                color: white;
                font-size: 14px;
                font-weight: 700;
                text-transform: none;

                &::after {
                  content: "\e901";
                  display: flex;
                  position: absolute;
                  top: 0;
                  right: 0;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 100%;
                  transform: translateX(50px);
                  transition: 300ms ease-in-out transform;
                  background: #121011;
                  font-family: icomoon;
                  font-size: 24px;
                  text-indent: 0;
                }

                &:hover {
                  &::after {
                    transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
