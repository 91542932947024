#algolia-autocomplete-container {
  .aa-dropdown-menu {
    position: fixed !important;
    z-index: 998 !important;
    top: 60px !important;
    right: auto !important;
    left: 50% !important;
    width: 100%;
    min-width: 0;
    height: auto;
    transform: translateX(-50%);
    border: 0;
    background: $lightgrey;

    @include mobile {
      border-radius: 0;
      box-shadow: none;
    }

    @include mobile {
      top: 60px !important;
      width: 100vw;
      overflow-y: scroll;
    }

    .autocomplete-wrapper {
      position: relative;
      flex-direction: column-reverse;
      max-width: 1920px;
      margin: 0 auto;
      padding-bottom: 40px;
      background: white;

      @include mobile {
        flex-direction: column-reverse;
      }

      > div {
        width: 100%;
        min-width: 0;

        &.col9 {
          border: 0;
        }

        @include mobile {
          width: 100%;
        }
      }

      .other-sections {
        margin: 15px;
      }

      .category {
        padding-left: 0;
        color: $black;
        font-size: 18px;
        text-transform: none;
      }

      .aa-suggestion {
        &.aa-cursor {
          .algoliasearch-autocomplete-hit {
            background: none;
          }
        }
      }

      .algoliasearch-autocomplete-hit {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 5px;
        padding: 0;
        padding-left: 0;
        color: $black;

        @include mobile {
          flex-direction: row;
        }

        em {
          color: $brightblue;
        }

        .thumb {
          aspect-ratio: 1/1;
          line-height: 1;

          @include mobile {
            min-width: 80px;
          }

          img {
            width: 100%;
            font-size: 0;
          }
        }

        .info {
          margin-top: 10px;
          margin-left: 0;
          overflow: unset;
          text-overflow: unset;
          white-space: normal;

          @include mobile {
            margin-top: 0;
            margin-left: 10px;
          }

          .algoliasearch-autocomplete-category {
            display: none;
            margin-top: 5px;
          }

          .algoliasearch-autocomplete-price {
            height: auto;
            margin-top: 5px;
          }
        }
      }

      .aa-no-results {
        padding-left: 0;
        color: $black;
        font-style: normal;
      }

      .aa-dataset-products {
        position: relative;
        width: 100%;

        .aa-no-results-products {
          padding: 15px;
          background: white;
        }

        .aa-suggestions {
          display: flex !important;
          flex-wrap: wrap;
          margin: auto;
          padding: 15px;

          .aa-suggestion {
            width: calc(100% / 6);
            padding: 10px;
            outline: 1px solid $grey;
            background: white;

            @include fullhd {
              width: calc(100% / 6);
            }

            @include desktop {
              width: calc(100% / 5);
            }

            @include laptop {
              width: calc(100% / 5);
            }

            @include tabletlandscape {
              width: calc(100% / 4);
            }

            @include tabletportrait {
              width: calc(100% / 3);
            }

            @include mobile {
              width: calc(100%);
              min-width: 0;
              max-width: 100%;
            }

            &.aa-cursor {
              .algoliasearch-autocomplete-hit {
                background: transparent;
              }
            }

            .algoliasearch-autocomplete-hit {
              .thumb {
              }

              .info {
                .algoliasearch-autocomplete-category {
                  display: none;
                }

                .algoliasearch-autocomplete-price {
                  margin-top: 5px;

                  .after_special {
                    margin-right: 5px;
                    color: $black;
                    font-size: 24px;
                    font-weight: 800;
                    line-height: 24px;

                    @include mobile {
                      font-size: 18px;
                      line-height: 18px;
                    }
                  }

                  .before_special {
                    display: block;
                    color: $black;
                    font-size: 15px;
                    font-weight: 700;
                    text-decoration: line-through;

                    &::before {
                      content: "was";
                      display: inline-block;
                      margin-right: 3px;
                      text-decoration: none;
                    }
                  }

                  .msrp-wrapper {
                    display: flex;
                    align-items: center;

                    .msrp-price {
                      margin-top: 2px;
                      margin-left: 5px;
                      color: $red;
                      font-size: 16px;
                      font-weight: 700;
                      line-height: 16px;
                      text-decoration: line-through;

                      @include mobile {
                        margin-left: 5px;
                        font-size: 13px;
                      }
                    }

                    .msrp-tag {
                      display: flex;
                      position: relative;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 16px;
                      margin-left: 13px;
                      padding-right: 2px;
                      background: $red;
                      color: white;
                      font-size: 11px;
                      font-weight: 500;
                      text-decoration: none;

                      @include mobile {
                        width: 24px;
                        height: 12px;
                        margin-left: 10px;
                        font-size: 9px;
                        line-height: 12px;
                      }

                      &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -8px;
                        border-top: 8px solid transparent;
                        border-right: 8px solid $red;
                        border-bottom: 8px solid transparent;

                        @include mobile {
                          left: -6px;
                          border-top: 6px solid transparent;
                          border-right: 6px solid $red;
                          border-bottom: 6px solid transparent;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        #autocomplete-products-footer {
          bottom: -81px;
          width: 100%;
          background: $red;
          color: $black;

          a {
            color: white;
          }
        }
      }
    }

    #search-all {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      margin: 15px 0 0 15px;
      border-radius: 3px;
      background: $brightblue;
      color: white;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }

    .close-search {
      display: flex;
      position: absolute;
      top: 15px;
      right: 5px;
      align-items: center;
      justify-content: center;
      max-width: 40px;
      height: 40px;
      cursor: pointer;

      &::before {
        content: "\e902";
        font-family: icomoon;
        font-size: 21px;
      }
    }
  }
}

.aa-Panel {
  position: fixed !important;
  top: 80px !important;
  left: 0 !important;
  width: 100% !important;
  height: 100%;
  margin-top: 0;
  padding: 0;
  animation: fadeIn 500ms;
  border: 0;
  background: rgb(0 0 0 / 70%);

  @include mobile {
    left: 0 !important;
    width: 100vw !important;
    height: calc(100% - 60px);
    overflow-y: scroll;
    transform: none;
    transition: 300ms ease-in-out top;
  }

  @include tabletlandscape {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -120px;
      left: 0;
      width: 30px;
      background: rgb(0 0 0 / 70%);
    }
  }

  @include tabletportrait {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -120px;
      left: 0;
      width: 30px;
      background: rgb(0 0 0 / 70%);
    }
  }

  &.productColumn2,
  &.productColumn1 {
    .aa-PanelLayout {
      .aa-Source {
        padding-top: 0 !important;

        @include mobile {
          padding: 0;
        }

        &[data-autocomplete-source-id="products"],
        &[data-autocomplete-source-id="querySuggestionsPlugin"] {
          flex: 1;
          width: 100% !important;
          margin-left: 0 !important;
          border-left: 0;
        }
      }
    }
  }

  .aa-PanelLayout {
    display: grid;
    position: absolute !important;
    z-index: 998 !important;
    top: 0 !important;
    right: auto !important;
    left: 50% !important;
    grid-gap: 0;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    width: 100% !important;
    min-width: 0 !important;
    max-width: calc(100vw - 510px) !important;
    margin: 0 auto;
    padding: 0;
    padding-bottom: 40px;
    transform: translateX(-50%);
    transition: 300ms ease-in-out top;
    border: 0;
    border-radius: 0 0 30px 30px;
    background: #f5f5f5;

    @include tabletlandscape {
      max-width: calc(100vw - 60px) !important;
    }

    @include tabletportrait {
      max-width: calc(100vw - 60px) !important;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      max-width: 100vw !important;
      border-radius: 0;

      &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: -50px;
        left: 0;
        width: 100%;
        height: 50px;
        background: $lightgrey;
      }
    }

    .aa-Source {
      width: 100% !important;
      min-width: 100%;
      margin: 0 !important;
      padding-right: 15px;
      padding-left: 15px;

      .aa-SourceNoResults {
        padding-left: 0;

        @include mobile {
          padding-left: 15px;
        }
      }

      &:not(&[data-autocomplete-source-id="products"]) {
        padding-right: 0;

        .aa-SourceHeader {
          @include mobile {
            padding: 0 15px;
          }
        }

        .aa-List {
          @include mobile {
            margin-bottom: 0;
          }

          .aa-Item {
            @include mobile {
              margin-bottom: 0 !important;
              padding-bottom: 5px;
              border-bottom: 1px solid $grey;

              a {
                position: relative;
                width: 100%;
                padding: 5px 15px 4px;

                &::after {
                  content: "\e906";
                  position: absolute;
                  top: 13px;
                  right: 15px;
                  transform: rotate(-90deg);
                  color: $darkgrey;
                  font-family: icomoon;
                  font-size: 8px;
                }
              }
            }

            &[aria-selected="true"] {
              a {
                text-decoration: underline;
              }
            }

            .algoliasearch-autocomplete-hit {
              svg {
                width: 20px;
                height: 20px;
                max-height: 20px;
                margin-right: 5px;
                transform: translateY(4px);
                opacity: 0.7;
              }

              mark {
                color: $black !important;
                font-weight: 500 !important;
              }
            }
          }
        }
      }

      &[data-autocomplete-source-id="querySuggestionsPlugin"] {
        grid-area: 1 / 1 / 2 / 2;
      }

      &[data-autocomplete-source-id="categories"] {
        grid-area: 2 / 1 / 3 / 2;

        .aa-SourceHeader {
          display: none;
        }

        .aa-SourceNoResults {
          display: none;
        }
      }

      &[data-autocomplete-source-id="pages"] {
        grid-area: 3 / 1 / 4 / 2;

        .aa-SourceHeader {
          display: none;
        }

        .aa-List {
          .aa-Item {
            margin-bottom: 5px;

            .algoliasearch-autocomplete-hit {
              padding: 0;
            }
          }
        }
      }

      &[data-autocomplete-source-id="products"] {
        grid-area: 1 / 2 / 4 / 3;
        min-width: unset !important;

        .aa-SourceHeader {
          padding: 0 0 5px;
        }

        .autocomplete-products-footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 5px;
          color: $darkgrey;
          font-size: 14px;
          font-weight: 500;
          text-transform: none;

          @include mobile {
            display: block;
            position: relative;
            justify-content: flex-start;
            margin-top: 0;
            margin-bottom: 15px;
            border-bottom: 1px solid $grey;

            &::after {
              content: "\e906";
              position: absolute;
              top: 13px;
              right: 15px;
              transform: rotate(-90deg);
              color: $darkgrey;
              font-family: icomoon;
              font-size: 8px;
            }
          }

          span {
            @include mobile {
              display: block;
            }

            p {
              @include mobile {
                margin-bottom: 0;
                padding: 10px 15px;
                color: $black;
              }
            }

            a {
              margin-right: 5px;

              @include mobile {
                display: flex;
                gap: 5px;
                padding: 10px 15px;
              }

              span {
                color: $darkgrey;
              }
            }
          }
        }

        .aa-SourceFooter {
          padding-bottom: 0;

          @include mobile {
            margin-top: 15px;
            padding-bottom: 100px;
            border-top: 1px solid $grey;
          }
        }
      }

      &[data-autocomplete-source-id="categories"],
      &[data-autocomplete-source-id="querySuggestionsPlugin"] {
        .aa-SourceHeader {
          padding-left: 0;
          color: $black;
          font-size: 18px;
          font-weight: 700;
          text-transform: capitalize;

          p {
            font-weight: 700;
          }
        }

        .aa-SourceNoResults {
          padding-left: 0;

          @include mobile {
            padding-left: 15px;
          }
        }

        .aa-List {
          .aa-Item {
            margin-bottom: 5px;

            a.algoliasearch-autocomplete-hit {
              overflow: hidden;
              text-overflow: ellipsis;
              text-wrap: nowrap;

              &:has(svg.algolia-glass-suggestion) {
                display: inline;
                padding: 0 30px 0 0 !important;

                svg {
                  top: 3px;
                  right: -7px;
                  left: auto;
                }
              }
            }

            .algoliasearch-autocomplete-hit {
              display: block;
              height: 100%;
              margin-bottom: 0;
              padding: 0;
              padding-left: 0;
              color: $black;

              &:hover {
                background: none;
              }

              @include mobile {
                flex-direction: row;
              }

              mark {
                color: $brightblue;
                font-weight: 700;
              }
            }
          }
        }
      }

      &[data-autocomplete-source-id="products"] {
        order: 3;

        .aa-List {
          display: grid;
          grid-gap: 0;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(3, 1fr);
          margin: auto;
          padding: 0;
          gap: 15px;

          @include mobile {
            display: flex;
            flex-direction: column;
            padding: 0 15px;
          }

          .aa-Item {
            width: 100%;
            margin: 0;
            padding: 10px;
            background: transparent;

            // @include fullhd {
            //   width: calc(100% / 3) !important;
            // }

            // @include desktop {
            //   width: calc(100% / 2) !important;
            // }

            // @include laptop {
            //   width: calc(100% / 2) !important;
            // }

            // @include tabletlandscape {
            //   width: calc(100% / 2) !important;
            // }

            // @include tabletportrait {
            //   width: calc(100% / 2) !important;
            // }

            @include mobile {
              min-width: 0;
              max-width: 100%;
            }

            .algoliasearch-autocomplete-hit {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 100%;
              margin-bottom: 5px;
              padding: 0;
              padding-left: 0;
              color: $black;
              gap: 10px;

              @include mobile {
                flex-direction: row;
              }

              em {
                color: $brightblue;
              }

              .thumb {
                max-width: 70px;
                background: $lightgrey;
                line-height: 1;
                aspect-ratio: 1/1;

                @include mobile {
                  min-width: 80px;
                }

                img {
                  width: 100%;
                  border-radius: 10px;
                  font-size: 0;
                }
              }

              .info {
                display: inline;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                margin: 0;
                margin-top: 0;
                overflow: unset;
                font-size: 12px;
                text-overflow: unset;
                white-space: normal;

                .desc {
                  display: -webkit-box;
                  margin-bottom: 0;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: normal;
                  hyphens: auto;
                  overflow-wrap: anywhere;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }

                @include mobile {
                  margin-top: 0;
                  margin-left: 10px;
                  padding-left: 0;
                }

                mark {
                  color: $black;
                  font-weight: 700;
                }

                .algoliasearch-autocomplete-category {
                  display: none;
                }

                .algoliasearch-autocomplete-price {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  height: auto;
                  margin-top: 5px;

                  .after_special {
                    margin-right: 5px;
                    color: $black;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 16px;

                    @include mobile {
                      font-size: 18px;
                      line-height: 18px;
                    }
                  }

                  .before_special {
                    display: block;
                    width: 100%;
                    color: $darkgrey;
                    font-size: 12px;
                    font-weight: 700;
                    text-decoration: none;

                    &::before {
                      content: "was";
                      display: inline-block;
                      margin-right: 3px;
                      text-decoration: none;
                    }
                  }

                  .msrp-wrapper {
                    display: flex;
                    align-items: center;

                    .msrp-price {
                      margin-top: 2px;
                      margin-left: 0;
                      color: $red;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 12px;
                      text-decoration: line-through;

                      @include mobile {
                        margin-left: 5px;
                        font-size: 13px;
                      }
                    }

                    .msrp-tag {
                      display: flex;
                      position: relative;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 16px;
                      margin-left: 13px;
                      padding-right: 2px;
                      background: $red;
                      color: white;
                      font-size: 11px;
                      font-weight: 500;
                      text-decoration: none;

                      @include mobile {
                        width: 24px;
                        height: 12px;
                        margin-left: 10px;
                        font-size: 9px;
                        line-height: 12px;
                      }

                      &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -8px;
                        border-top: 8px solid transparent;
                        border-right: 8px solid $red;
                        border-bottom: 8px solid transparent;

                        @include mobile {
                          left: -6px;
                          border-top: 6px solid transparent;
                          border-right: 6px solid $red;
                          border-bottom: 6px solid transparent;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #search-all {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 40px;
      margin: 15px 0 0 15px;
      border-radius: 3px;
      background: $red;
      color: white;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .close-search {
    display: flex;
    position: absolute;
    top: 15px;
    right: 15px;
    align-items: center;
    justify-content: center;
    max-width: 40px;
    height: 40px;
    cursor: pointer;

    &::before {
      content: "\e902";
      font-family: icomoon;
      font-size: 21px;
    }
  }
}

body {
  &.scrolled {
    .aa-Panel {
      @include mobile {
        top: 60px !important;
      }

      // .aa-PanelLayout {
      //   @include mobile {
      //     top: 60px !important;
      //   }
      // }
    }

    &.sub-menu {
      top: 80px !important;

      .aa-Panel {
        @include mobile {
          top: 60px !important;
        }

        // .aa-PanelLayout {
        //   @include mobile {
        //     top: 60px !important;
        //   }
        // }
      }
    }
  }

  &:not(.scrolled) {
    .aa-Panel {
      @include mobile {
        top: 140px !important;
      }

      // .aa-PanelLayout {
      //   @include mobile {
      //     top: 140px !important;
      //   }
      // }
    }

    &.sub-menu {
      .aa-Panel {
        @include mobile {
          top: 60px !important;
        }

        // .aa-PanelLayout {
        //   @include mobile {
        //     top: 140px !important;
        //   }
        // }
      }
    }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
