.slick-slider-featured {
  .item {
    .inner-container {
      display: flex;

      .image {
        img {
          mix-blend-mode: darken;
        }
      }

      .text {
      }
    }
  }
}
