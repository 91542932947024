body {
  &.cms-amasty-faq-home-page {
    overflow-x: hidden;

    .page-main {
      max-width: 1920px;
      padding: 0;
    }

    .breadcrumbs {
      display: none;
    }

    .minicart-icons {
      >p {
        display: none;
      }
    }

    .page-title-wrapper,
    .am-search-box-wrapper {
      position: relative;
      background: $blue;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -2000px;
        width: 2000px;
        height: 100%;
        background: $blue;
      }

      &::after {
        right: -2000px;
        left: auto;
      }
    }

    .am-back-to-product-widget {
      display: none;
    }

    .page-title-wrapper {
      padding-top: 20px;

      .page-title {
        margin-bottom: 0;
        color: white;
      }
    }

    .am-search-box-wrapper {
      padding-bottom: 20px;

      .am-widget-center {
        width: 100% !important;
        max-width: 500px;

        .amfaq-search {
          padding: 20px 15px 0;

          form {
            position: relative;

            .am-input {
              border: none;
              border-radius: 3px !important;
              font-family: Nunito;
            }

            .button.am-button {
              top: 50%;
              transform: translateY(-50%);
            }

            #amfaq_search_autocomplete {
              margin-top: -3px;

              ul {
                li {
                  border: none;
                  border-bottom: 1px solid $grey;

                  &.selected {
                    background: $lightblue;
                  }
                }
              }
            }
          }
        }
      }
    }

    .widget {
      margin-top: 20px;

      .am-widget-category {
        .am-category-container {
          .am-category-header {
            .faq-category-title {
              font-size: 28px;
              font-weight: 700;
            }
          }

          .amfaq_questions {
            padding: 15px;
            background: white;

            .am-item {
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
                border-bottom: none;

                .am-title {
                  margin-bottom: 0;
                }
              }

              .am-title {
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: 700;
                line-height: 19px;

                .am-minus {
                  &::before {
                    content: "\e906";
                    color: $black;
                    font-family: icomoon;
                    font-size: 10px;
                  }
                }

                .am-plus {
                  &::before {
                    content: "\e906";
                    transform: rotate(180deg);
                    color: $black;
                    font-family: icomoon;
                    font-size: 10px;
                  }
                }
              }

              .am-content {
                .am-links {
                  font-size: 14px;
                }
              }
            }
          }

          .view-all {
            margin-top: 20px;
          }
        }
      }
    }
  }

  &.faq-question-view,
  &.faq-category-view {
    .page-main {
      max-width: 1920px;

      .columns {
        display: flex;
        flex-direction: row;

        .column {
          flex-basis: auto;
          flex-grow: unset;
          order: 2;
          width: calc(100% - 300px);
          padding-top: 20px;

          @include mobile {
            width: 100%;
          }

          .page-title-wrapper {
            .page-title {
              margin-bottom: 15px;
              font-size: 28px;
            }
          }

          .question_view {
            border-bottom: 0;
          }

          .widget {
            margin-top: 20px;
            padding: 15px;
            background: white;

            .am-item {
              &:last-child {
                margin-bottom: 0;
                border-bottom: none;

                .am-title {
                  margin-bottom: 0;
                }
              }

              .am-title {
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: 700;
                line-height: 19px;

                .am-minus {
                  &::before {
                    content: "\e906";
                    color: $black;
                    font-family: icomoon;
                    font-size: 10px;
                  }
                }

                .am-plus {
                  &::before {
                    content: "\e906";
                    transform: rotate(180deg);
                    color: $black;
                    font-family: icomoon;
                    font-size: 10px;
                  }
                }
              }

              .am-content {
                .amfaq-question-footer-wrapper {
                  .am-links {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .question_view {
            .amfaq-question-footer-wrapper {
              display: none;
            }
          }

          .ask-question-form {
            display: none;
          }
        }

        .sidebar {
          flex-basis: auto;
          flex-grow: unset;
          order: 1;
          width: auto;
          min-width: 300px;

          @include mobile {
            width: 100%;
          }

          #amfaq_search_autocomplete {
            margin-top: -3px;
            border: 1px solid $grey;

            ul {
              li {
                border: none;
                border-bottom: 1px solid $grey;

                &.selected {
                  background: $lightblue;
                }
              }
            }
          }

          .amfaq-search {
            form {
              .am-input {
                font-family: Nunito;
              }

              .button.am-button {
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

          .amfaq_categories {
            border: 1px solid $grey;
            border-radius: 0;
            background: white;

            .item {
              &.title {
                border-radius: 0;
                text-align: left;
              }

              &.current {
                background: $lightblue;
              }
            }
          }
        }
      }
    }
  }
}
