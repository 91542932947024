body {
  &.ambrand-index-index {
    .category-view {
      .category-image {
        img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
        }
      }
    }
  }

  &.catalog-category-view,
  &.catalogsearch-result-index,
  &.category-default-category {
    background: #fff;

    @include mobile {

    }

    &.scrolled {
      .algolia-infos {
        top: 0 !important;
      }
    }

    .page-header {
      &.scrolled {
        @include media(mobile, tabletportrait) {
          top: -170px !important;
        }
      }
    }

    .page-bottom {
      max-width: 1920px;

      .content {
        .category-description {
          padding: 0 15px;
        }
      }
    }

    .category-view {
      .category-image {
        img {
          margin: 0 auto;
        }
      }

      .row-full-width-inner {
        padding: 0 20px;

        @include mobile {
          padding: 0 15px;
        }
      }

      .category-description {
        ul {
          display: flex;
          width: calc(100% + 15px);
          padding: 0;

          li {
            display: flex;
            align-items: center;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 0;
            padding: 0;
            transition: 300ms ease-in-out all;
            border-radius: 15px;
            background: $green;
            list-style: none;
            white-space: nowrap;

            &:hover {
              background: $blue;
              color: white;
            }

            a {
              display: block;
              padding: 5px 10px;
              color: $blue;
              font-weight: 700;

              &:hover {
                color: white;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .page-main {
      max-width: 1920px;

      .page-title-wrapper {
        .page-title {
          margin-bottom: 15px;
          font-size: 21px;
          font-weight: 700;
        }
      }

      .columns {
        display: flex;
        z-index: auto;
        flex-direction: column;
        padding-bottom: 50px;

        .column {
          position: relative;
          width: 100%;
          padding: 0;

          @include mobile {
            padding-top: 80px;
          }

          .toolbar {
            .toolbar-sorter {
              #sorter {
                height: 40px;
                border-radius: 0;
                line-height: 40px;

                &:not([disabled]) {
                  &:not([type="checkbox"]) {
                    &:focus {
                      border: 1px solid $green;
                      box-shadow: none;
                    }

                    &.mage-error {
                      border: 1px solid $red;
                      color: $black;

                      &:focus {
                        border: 1px solid $red;
                        box-shadow: none;
                      }
                    }
                  }
                }
              }
            }
          }

          .catalog-topnav {
            width: calc(100% - 300px);

            .block.filter {
              margin-bottom: 0;

              .block-title {
                display: none;
              }

              .block-content {
                .filter-subtitle {
                  margin-top: 10px;
                  margin-bottom: 20px;
                  font-size: 21px;
                  font-weight: 700;
                }

                .mobile-filters-clear-all {
                  display: none;

                  @include mobile {
                    display: inline;
                    margin-left: 10px;
                    font-size: 14px;
                    font-weight: 700;
                  }
                }

                .filter-options {
                  padding: 0;
                  user-select: none;

                  .filter-options-item {
                    margin-right: 10px;
                    margin-bottom: 10px;

                    .filter-options-title {
                      display: flex;
                      position: relative;
                      align-items: center;
                      justify-content: flex-start;
                      width: 160px;
                      height: 50px;
                      margin-bottom: 0;
                      padding: 0 15px;
                      border: 1px solid $grey;
                      background: white;
                      cursor: pointer;

                      @include mobile {
                        border: none;
                        border-top: 1px solid $grey;
                      }

                      &::after {
                        content: "\e906";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        font-family: icomoon;
                        font-size: 9px;
                      }
                    }

                    &.active {
                      .filter-options-title {
                        &::before {
                          content: "";
                          position: absolute;
                          z-index: 997;
                          bottom: -1px;
                          left: 0;
                          width: 100%;
                          height: 1px;
                          background: white;

                          @include mobile {
                            display: none;
                          }
                        }

                        &::after {
                          transform: rotate(180deg) translateY(50%);
                        }
                      }
                    }

                    .filter-options-content {
                      z-index: 996;
                      margin-top: -1px;
                      padding: 15px;
                      border: 1px solid $grey;
                      border-radius: 0;
                      background: white;
                      box-shadow: none;

                      @include mobile {
                        border: none;
                      }

                      .am-ranges {

                      }

                      .items {
                        .item {
                          @include mobile {
                            padding-left: 25px;
                          }

                          a.amshopby-filter-parent {
                            display: flex;
                            align-items: center;
                            height: 30px;
                            padding-left: 15px;
                            color: $black;
                            text-decoration: none;
                          }

                          .rating-summary {
                            .rating-result {
                              @include mobile {
                                width: 200px;

                                &::before {
                                  @include mobile {
                                    font-size: 50px;
                                  }
                                }
                              }

                              >span {
                                &::before {
                                  background: $orangegradient;
                                  background-clip: text;
                                  -webkit-text-fill-color: transparent;

                                  @include mobile {
                                    font-size: 50px;
                                  }
                                }
                              }
                            }

                            +.am-rating-up {
                              color: $black;
                            }
                          }
                        }
                      }

                      .amshopby-choice {
                        top: 0;
                        min-height: 30px;
                        margin: 0;
                        padding-left: 30px;

                        &::after {
                          top: 3px;
                          left: 3px;
                          width: 24px;
                          height: 24px;
                          background-size: 100%;
                        }

                        &::before {
                          width: 30px;
                          height: 30px;
                        }
                      }

                      .amshopby-flyout-block {
                        .amshopby-fly-out-view {
                          margin: 0;
                          padding: 0;

                          .item {
                            padding: 5px 0;

                            &:hover {
                              background: none;
                            }

                            a {
                              padding-left: 5px;
                              color: $black;
                              font-weight: 500;
                              line-height: 30px;
                            }

                            >.amshopby-choice {
                              min-height: 30px;
                              margin: 0;
                              padding-left: 30px;

                              &::after {
                                top: 3px;
                                left: 3px;
                                width: 24px;
                                height: 24px;
                                background-size: 100%;
                              }

                              &::before {
                                width: 30px;
                                height: 30px;
                              }
                            }

                            input[type="checkbox"] {
                              &:checked {
                                + .amshopby-choice {
                                  &::before {
                                    border-color: $grey;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .amshopby-slider-wrapper {
                        .amshopby-currency {
                          top: 50%;
                          bottom: auto;
                          left: 10px;
                          transform: translateY(-50%);
                          font-size: 14px;
                          font-weight: 700;
                          line-height: 14px;
                        }
                      }

                      .amshopby-search-box {
                        input {
                          max-width: fit-content;
                        }
                      }
                    }
                  }
                }
              }
            }

            margin-top: 0;

            @include mobile {
              &.show {
                display: block;
              }

              position: fixed;
              z-index: 1000;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              padding: 15px;
              transition: 300ms ease-in-out padding;
              background: $lightgrey;

              .block.filter {
                .block-title {

                }

                .block-content {
                  .filter-options {
                    display: flex;
                    flex-direction: column;

                    .filter-options-item {
                      width: 100%;
                      margin-bottom: 0;

                      .filter-options-title {
                        width: 100%;
                        padding-left: 0;
                        background: transparent;

                        &::after {

                        }
                      }

                      .filter-options-content {
                        position: relative;
                        padding: 0;
                        background: transparent;

                        .items {
                          background: transparent;
                          box-shadow: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .mobile-filters-trigger {
            display: none;

            &:not(.active) {
              position: absolute;
              top: 0;
              left: -15px;
              width: 50vw;
              height: 50px;
              outline: 1px solid $grey;

              button {
                width: 100%;
                height: 50px;
                border: none;
                border-radius: 0;
                background: white;
                color: $black;
                font-family: Nunito;
                font-weight: 700;

                &:hover {
                  border: none;
                }

                &:active {
                  border: none;
                  box-shadow: none;
                }
              }
            }

            @include mobile {
              display: block;
            }

            &.active {
              position: fixed;
              z-index: 1001;
              bottom: 15px;
              left: 15px;
              width: calc(100% - 30px);
              height: 50px;

              button {
                width: 100%;
                height: 50px;
                background: $brightblue;
                color: white;
                font-family: Nunito;
                font-weight: 700;
              }
            }
          }

          .mobile-filters-close {
            display: none;

            @include mobile {
              cursor: pointer;

              &.active {
                display: flex;
                position: fixed;
                z-index: 1001;
                top: 15px;
                right: 15px;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                &::before {
                  content: "\e902";
                  font-family: icomoon;
                  font-size: 24px;
                }

                span {
                  display: none;
                }
              }
            }
          }

          #amasty-shopby-product-list {
            .products {
              margin: 0;
              padding: 0;

              &.list {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 15px;

                @include fullhd {
                  grid-template-columns: repeat(5, 1fr);
                }

                @include desktop {
                  grid-template-columns: repeat(4, 1fr);
                }

                @include laptop {
                  grid-template-columns: repeat(4, 1fr);
                }

                @include tabletlandscape {
                  grid-template-columns: repeat(3, 1fr);
                }

                @include tabletportrait {
                  grid-template-columns: repeat(2, 1fr);
                }

                @include mobile {
                  grid-template-columns: repeat(1, 1fr);
                  width: calc(100%);
                  min-width: 0;
                  max-width: 100%;
                }

                .item {
                  @include mobile {
                    border-bottom: 1px solid $grey;
                  }

                  .product-item-info {
                    @include mobile {
                      flex-direction: row;
                    }

                    .product-item-photo {
                      position: relative;
                      min-width: 100px;
                    }
                  }
                }
              }
            }

            .toolbar-products {
              .pages {
                margin-top: 40px;
              }

              &:first-child {
                position: absolute;
                top: 0;
                right: 0;

                @include mobile {
                  top: 0;
                  left: 50%;
                  width: 50vw;
                  height: 50px;

                  .toolbar-amount {
                    display: none;
                  }

                  padding: 0;
                  outline: 1px solid $grey;
                }
              }

              .toolbar-amount {

              }

              .field.limiter {
                display: none;
              }

              .toolbar-sorter {
                position: relative;
                height: 50px;
                padding-top: 0;

                &::after {
                  content: "\e906";
                  position: absolute;
                  top: 50%;
                  right: 45px;
                  transform: translateY(-50%);
                  font-family: icomoon;
                  font-size: 9px;
                  pointer-events: none;

                  @include mobile {
                    right: 30px;
                  }
                }

                label {
                  position: absolute;
                  top: 50%;
                  left: 15px;
                  transform: translateY(-50%);
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 13px;
                  pointer-events: none;

                  @include mobile {
                    left: 0;
                    width: 100%;
                    font-weight: 700;
                    text-align: center;
                    text-indent: -15px;
                  }
                }

                #sorter {
                  display: inline-flex;
                  align-items: center;
                  width: 180px;
                  height: 50px;
                  margin-left: 0;
                  padding: 0 30px 0 0;
                  border: 1px solid $grey;
                  border-radius: 0;
                  font-family: Nunito;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 40px;
                  text-indent: 65px;
                  text-overflow: ellipsis;
                  appearance: none;

                  @include mobile {
                    width: 100%;
                    border: none;
                    color: white;
                    text-indent: -300px;
                  }

                  div {
                    display: flex;
                  }
                }

                .sorter-action {
                  top: 0;
                  height: 50px;

                  @include mobile {
                    display: none;
                  }

                  &::before {
                    line-height: 50px;
                  }
                }
              }
            }
          }
        }

        .sidebar {
          width: 100%;

          .block.filter {
            margin-bottom: 0;
          }

          .block-title {
            display: none;
          }

          .filter {
            .filter-content {
              .filter-actions {
                .filter-clear {
                  font-weight: 700;
                }
              }

              .mobile-filters-clear-all {
                display: none;
              }

              .filter-options,
              .filter-subtitle {
                display: none;
              }

              .filter-current {
                .items {
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  padding-left: 0;

                  .item {
                    height: 50px;
                    padding: 5px 15px 5px 30px;
                    border: 1px solid $grey;
                    border-radius: 0;

                    .amshopby-remove {
                      left: 10px;

                      &::before,
                      &::after {
                        background-color: $darkgrey;
                      }

                      &:hover {
                        &::before,
                        &::after {
                          background-color: $black;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.sidebar-additional {
            display: none;
          }
        }
      }

      #algolia_instant_selector {
        >.row {
          display: flex;

          @include media(mobile, tabletportrait) {
            flex-direction: column;
          }
        }

        #algolia-left-container {
          max-width: 350px;

          @include media(mobile, tabletportrait) {
            width: 100%;
            max-width: 100%;
          }

          #refine-toggle {
            display: block !important;
            height: 50px;
            margin: 0;
            border-radius: 3px;
            background-color: $brightblue;
            color: white;
            line-height: 50px;

            @include media(tabletlandscape, laptop, desktop, fullhd) {
              display: none !important;
            }
          }

          #instant-search-facets-container {
            position: relative;
            z-index: 2;

            @include media(mobile, tabletportrait) {
              display: flex !important;
              position: fixed;
              z-index: 9999;
              top: 0;
              right: 0;
              flex-flow: column nowrap;
              flex-wrap: wrap;
              width: 100%;
              max-width: 270px;
              height: 100%;
              padding-top: 50px;
              transform: translateX(100%);
              transition: 300ms ease-in-out transform;
              border: 1px solid $grey;
              background: white;

              &::before {
                content: "Filters & sorting";
                position: absolute;
                z-index: 1;
                top: 12px;
                left: 15px;
                font-size: 21px;
                font-weight: 700;

                @include media(mobile, tabletportrait) {
                  top: 14px;
                  font-size: 16px;
                  font-weight: 700;
                }
              }

              &::after {
                content: "";
                position: absolute;
                top: -1px;
                left: -1000px;
                width: 1000px;
                height: calc(100% + 2px);
                transition: 300ms ease-in-out opacity;
                opacity: 0;
                background: rgb(0 0 0 / 50%);
                pointer-events: none;
              }
            }

            &:not(.hidden-sm) {
              @include media(mobile, tabletportrait) {
                transform: translateX(0);

                &::after {
                  opacity: 1;
                }
              }
            }

            #current-refinements {
              display: none;
              order: -3;
              width: 100%;
            }

            .refinement-close-cross {
              display: none;
              position: absolute;
              z-index: 3;
              top: 5px;
              right: 5px;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;

              @include media(mobile, tabletportrait) {
                display: flex;
              }

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;

                &::before {
                  content: "\e902";
                  font-family: icomoon;
                  font-size: 18px;
                }
              }
            }

            .refinement-close-button {
              display: none;
              position: absolute;
              z-index: 2;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 80px;
              background: white;

              @include media(mobile, tabletportrait) {
                display: block;
              }

              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: calc(100% - 30px);
                height: 50px;
                margin: 15px auto;
                border-radius: 3px;
                background: $brightblue;
                color: white;
                font-size: 14px;
                font-weight: 700;
              }
            }

            #clear-refinements {
              order: -2;

              .ais-ClearRefinements {
                button {
                  position: relative;
                  width: auto;
                  height: 40px;
                  margin-right: 10px;
                  margin-bottom: 0;
                  border: none;
                  border-radius: 5px;
                  background: $red;
                  color: white;
                  text-decoration: none;

                  @include media(mobile, tabletportrait) {
                    width: 100%;
                    border-right: 0;
                    border-radius: 0;
                    text-align: left;
                  }
                }
              }
            }

            [class*="is-widget-container"] {
              &:last-child {
                margin-right: 0;
              }

              .ais-Panel {
                margin-top: 20px;
                margin-bottom: 0;
                border: none;

                @include media(mobile, tabletportrait) {
                  width: 100%;
                  margin-top: 0;
                  border-top: 1px solid $grey;
                }

                .ais-Panel-header {
                  position: relative;
                  height: auto;
                  padding: 15px 30px 15px 0;
                  background: transparent;
                  font-size: 18px;
                  cursor: pointer;
                  pointer-events: none;

                  @include media(mobile, tabletportrait) {
                    height: 50px;
                    padding-bottom: 15px;
                    padding-left: 15px;
                    border-right: 0;
                    background: white;
                    font-size: 14px;
                    font-weight: 500;
                    pointer-events: all;
                  }

                  span {
                    font-weight: 700;
                    pointer-events: none;

                    @include media(mobile, tabletportrait) {
                      font-weight: 500;
                    }

                    &::after {
                      @include media(mobile, tabletportrait) {
                        content: "\e906";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        color: $black;
                        font-family: icomoon;
                        font-size: 7px;
                      }
                    }

                    div {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                  }
                }

                .ais-Panel-header {
                  +.ais-Panel-body {
                    width: 100%;
                    max-height: 300px;
                    padding: 0;
                    overflow: hidden auto;

                    @include media(mobile, tabletportrait) {
                      position: absolute;
                      z-index: 1;
                      left: 0;
                      width: 100%;
                      height: auto;
                      max-height: 0;
                      padding: 0 15px;
                      overflow: hidden;
                      overflow: auto hidden;
                      transition: all 0.5s ease-in-out;
                      border: none;
                      border-top: 1px solid $grey;
                      background: white;
                    }

                    >div {
                      display: flex;
                      width: 100%;
                    }

                    .ais-HierarchicalMenu {
                      .ais-HierarchicalMenu-list {
                        .ais-HierarchicalMenu-item {
                          .ais-HierarchicalMenu-link {
                            color: $black;
                            font-size: 13px;

                            .ais-HierarchicalMenu-count {
                              color: $grey;
                              font-size: 11px;
                            }
                          }
                        }
                      }
                    }

                    .ais-RefinementList:not(ul) {
                      @include media(mobile, tabletportrait) {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                      }

                      .ais-RefinementList-searchBox {
                        display: none;

                        @include media(mobile, tabletportrait) {
                          margin-bottom: 15px;
                        }

                        .ais-SearchBox {
                          margin-bottom: 0;

                          .ais-SearchBox-form {
                            display: flex;

                            .ais-SearchBox-input {
                              min-width: 200px;
                              margin-right: 15px;
                              border: 1px solid $grey;
                              font-family: Nunito;

                              &:focus {
                                border: 1px solid $darkgrey;
                              }
                            }

                            .ais-SearchBox-reset {
                              top: 4px;
                              right: 15px;

                              &:hover {
                                background: transparent;
                              }
                            }
                          }
                        }
                      }

                      .ais-RefinementList-list {
                        display: flex;
                        gap: 5px;
                        flex-direction: column;

                        @include media(mobile, tabletportrait) {
                          flex-direction: column;
                        }

                        li {
                          margin-bottom: 0;

                          >div {
                            height: 26px;
                            white-space: nowrap;

                            label {
                              display: flex;
                              align-items: center;
                              height: 26px;
                              font-size: 13px;

                              input {
                                top: 0;
                                width: 18px;
                                height: 18px;
                                margin-right: 15px;
                                background: $lightgrey;

                                &::before {
                                  content: "";
                                  width: 10px;
                                  height: 10px;
                                  background: $black;
                                  font-size: 14px;
                                }
                              }

                              .ais-RefinementList-count {
                                margin-left: 3px;
                                color: $grey;
                                font-size: 11px;
                                line-height: 26px;
                              }
                            }
                          }
                        }
                      }
                    }

                    .ais-RangeInput {
                      width: 100%;
                      padding-top: 15px;

                      .ais-RangeInput-form {
                        width: 100%;

                        .ais-RangeInput-separator {
                          margin: 0 5px;
                        }

                        .ais-RangeInput-label {
                          position: relative;

                          &::before {
                            content: "Max";
                            position: absolute;
                            top: -16px;
                            left: 0;
                            font-size: 12px;
                          }

                          &:first-child {
                            &::before {
                              content: "Min";
                            }
                          }

                          input {
                            height: 40px;
                          }
                        }

                        .ais-RangeInput-submit {
                          margin-left: 5px;
                          padding: 0 15px;
                          background: $black;
                          color: white;
                          font-weight: 700;
                        }
                      }
                    }

                    .ais-RangeSlider {
                      width: 100%;
                      max-width: 500px;
                      margin: 0 15px;
                      padding-bottom: 10px;

                      >div {
                        margin: 0;
                        margin-top: 30px;

                        .rheostat-background {
                          top: 2px;
                          height: 1px;
                        }

                        .rheostat-tooltip {
                          top: -18px;
                          color: $darkgrey;
                          font-size: 11px;
                          font-weight: 500;
                        }

                        .rheostat-handle {
                          top: -5px;
                          width: 15px;
                          height: 15px;
                          background-color: $black;
                        }

                        .rheostat-handle-lower {
                          .rheostat-tooltip {
                            left: 0;
                            margin-left: 0;
                            transform: none;
                          }
                        }

                        .rheostat-handle-upper {
                          .rheostat-tooltip {
                            right: 0;
                            margin-left: 0;
                            transform: none;
                          }
                        }

                        .rheostat-progress {
                          height: 3px;
                          background-color: $black;
                        }
                      }
                    }

                    @include media(mobile, tabletportrait) {
                      position: relative;
                      border: 0;
                    }
                  }
                }

                .ais-Panel-body {
                  padding: 0;

                  .ais-Panel {
                    border-top: 0;
                  }
                }

                &.active {
                  .ais-Panel-body {
                    display: flex;
                    width: calc(100% + 2px);
                    height: auto;
                    margin-top: -1px;
                    margin-left: -1px;
                    padding: 0 15px 30px;
                    overflow: visible;

                    @include media(mobile, tabletportrait) {
                      width: 100%;
                      height: auto;
                      max-height: 200px;
                      margin: auto;
                      overflow-y: auto;
                      border-right: none;
                      border-bottom: none;
                      border-left: none;
                      background: transparent;
                    }
                  }

                  .ais-Panel-header {
                    background: transparent;

                    span {
                      div {
                        font-weight: 700;
                      }

                      &::after {
                        content: "\e906";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%) rotate(180deg);
                        font-family: icomoon;
                        font-size: 10px;

                        @include media(mobile, tabletportrait) {
                          font-size: 7px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          #current-refinements {
            .ais-CurrentRefinements {
              .ais-CurrentRefinements-list {
                .ais-CurrentRefinements-item {
                  .ais-CurrentRefinements-category {
                    .ais-CurrentRefinements-delete {
                      display: inline-block;

                      &:hover {
                        margin-left: 5px;
                        color: $black;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        #algolia-right-container {
          width: 100%;

          @include mobile {
            width: 100%;
          }

          #instant-search-bar {
            display: none;
          }

          .hits {
            #instant-search-results-container {
              .ais-Hits {
                padding-top: 15px;
                padding-bottom: 30px;
              }
            }

            .algolia-infos {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 0 0 15px;
              transition: 300ms ease-in-out top;
              border-bottom: 1px solid $grey;
              background: transparent;
              gap: 15px;

              @include media(mobile, tabletportrait) {
                position: sticky;
                z-index: 98;
                top: 90px;
                padding-top: 15px;
                background: rgb(255 255 255 / 50%);
                backdrop-filter: blur(5px);
              }

              @include tabletportrait {
                top: 70px;
              }

              > div {
                display: flex;
                align-items: center;
                float: none;
              }

              .ais-Stats {
                .ais-Stats-text {
                  font-size: 0;

                  strong {
                    span {
                      color: $black;
                      font-size: 14px;
                      font-weight: 500;

                      &::after {
                        content: " products";
                      }

                      @include mobile {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }

              .sorting {
                position: relative;
                margin-left: auto;

                .sort-by-label {
                  position: absolute;
                  z-index: 1;
                  left: 10px;
                  color: $black;
                  pointer-events: none;
                }

                #algolia-sorts {
                  .ais-SortBy {
                    position: relative;
                    padding-right: 0;

                    &::after {
                      content: "";
                      position: absolute;
                      top: 50%;
                      right: 10px;
                      width: 15px;
                      height: 15px;
                      transform: translateY(-50%);
                      background-color: $black;
                      pointer-events: none;
                      mask-image: url("../images/icons/sort.svg");
                      mask-size: contain;
                      mask-repeat: no-repeat;
                    }

                    @include media(mobile, tabletportrait) {
                      padding-right: 0;
                      border-right: 0;
                    }

                    select {
                      width: 200px;
                      height: 40px;
                      padding: 0 30px 0 0;
                      border: 1px solid $grey;
                      background: $lightgrey;
                      color: $black;
                      font-family: Nunito;
                      font-size: 14px;
                      font-weight: 500;
                      appearance: none;
                      text-indent: 35px;
                      text-overflow: ellipsis;
                      text-transform: lowercase;
                      cursor: pointer;

                      @include media(mobile, tabletportrait) {
                        width: 140px;
                        font-size: 12px;
                      }

                      @include tabletportrait {
                        width: 160px;
                      }

                      @media screen and (max-width: 375px) {
                        width: 120px;
                        font-size: 0;
                      }

                      @media screen and (max-width: 355px) {
                        width: 100px;
                      }

                      @media screen and (max-width: 335px) {
                        width: 80px;
                      }

                      &:focus {
                        box-shadow: none;
                      }
                    }
                  }
                }
              }

              .algolia-clearfix {
                display: none;
              }

              #open-filters {
                display: none;

                @include media(mobile, tabletportrait) {
                  display: inline-flex;
                  position: relative;
                  align-items: center;
                  justify-content: center;
                  width: auto;
                  height: 40px;
                  padding: 0 40px 0 10px;
                  float: right;
                  border: 1px solid $grey;
                  background: $lightgrey;
                  color: $black;
                  cursor: pointer;
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: 10px;
                  width: 15px;
                  height: 15px;
                  transform: translateY(-50%);
                  background-color: $black;
                  pointer-events: none;
                  mask-image: url("../images/icons/filter.svg");
                  mask-size: contain;
                  mask-repeat: no-repeat;
                  mask-position: center;
                }
              }
            }

            #algolia-stats {
              display: flex;
            }
          }
        }
      }
    }

    .am_shopby_apply_filters {
      z-index: 1001;

      .am-show-button {
        padding: 15px;

        button {
          width: 100%;
          height: 50px;
        }

        .am-items {
          line-height: 19px;
        }
      }
    }
  }

  &.catalogsearch-result-index {
    .page-main {
      .page-title-wrapper {
        margin-top: 30px;
      }
    }
  }
}
