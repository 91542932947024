@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

body {
  font-family: Nunito, sans-serif;
  font-weight: 500;

  &.checkout-cart-index {
    padding-top: 60px;

    .page-main {
      .page-title-wrapper {
        padding-top: 30px;
      }
    }
  }

  &.page-layout-checkout {
    padding-top: 60px;

    .page-header {
      @include mobile {
        height: 60px;
      }

      .header {
        .logo {
          display: block;
          max-width: 140px;
          margin: 0 auto;
          margin-top: 5px;
          float: none;
        }
      }
    }

    .page-main {
      position: relative;
      max-width: 1600px;

      @include mobile {
        padding: 0;
      }

      @include tabletportrait {
        padding: 0 10px;
      }

      #timer-default {
        display: none;
      }

      .field.choice {
        &::before {
          display: none;
        }
      }

      .progress {
        display: none;
        position: fixed;
        z-index: 999;
        top: 60px;
        left: 0;
        min-width: 0;
        height: 10px;
        transition: all 0.1s linear 0s;
        background-color: $green;

        @include mobile {
          display: block;
          top: auto;
          bottom: 0;
        }
      }

      #return {
        display: flex;
        position: absolute;
        top: 10px;
        left: 30px;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 50px;
        border: 1px solid $grey;
        color: $darkgrey;
        font-weight: 700;

        @include mobile {
          position: fixed;
          z-index: 999;
          top: 10px;
          left: 10px;
          width: auto;
          height: 40px;
          border-color: transparent;
          background: transparent;
          color: white;
        }

        &::before {
          content: "\e906";
          margin-right: 5px;
          transform: rotate(90deg);
          font-family: icomoon;
          font-size: 5px;
        }

        &:hover {
          border-color: $black;
          color: $black;
          text-decoration: none;

          &::before {
            color: $black;
          }
        }

        @include mobile {
          &:hover {
            border-color: transparent;
            color: white;

            &::before {
              color: white;
            }
          }
        }
      }

      #checkout {
        margin: 0;
        padding: 0;
        background: none;

        .ampickup-map-popup {
          .ampickup-content {
            width: calc(100% - 20px);
            max-width: 1024px;
          }
        }

        input {
          &[type="text"],
          &[type="email"],
          &[type="password"],
          &.input-text {
            &:not(#search) {
              max-width: none;
            }
          }
        }

        .control._with-tooltip {
          input {
            width: 100%;
          }

          .field-tooltip {
            top: 8px;
            right: 10px;
          }
        }

        fieldset.street {
          .field {
            margin-top: 0;
          }
        }

        select {
          position: relative;
          max-width: none;
          padding-right: 30px;
        }

        .checkout-header {
          width: 100%;
          margin-bottom: 0;
          padding: 0 30px;

          @include mobile {
            padding: 0 15px;
          }

          h1 {
            margin: 0;
            text-align: center;

            @include mobile {
              max-width: 580px;
              text-align: left;
            }
          }
        }

        .authentication-wrapper {
          margin-top: -40px;
          margin-right: 0;
          padding: 0 10px;

          @include media(mobile, tabletportrait) {
            padding: 0 10px;
          }

          @include mobile {
            margin-top: 20px;
          }

          button {
            &.action {
              display: flex;
              width: 120px;
              float: right;
              border-radius: 3px;
              background: $orange;
              color: white;
              font-weight: 700;
              text-decoration: none;
            }
          }
        }

        .am-opc-wrapper {
          flex-wrap: wrap;

          .checkout-column {
            flex: 1;
            min-width: 375px;
            max-width: 520px;
            margin: 0 auto;

            @include mobile {
              min-width: 0;
            }

            .checkout-payment-method {
              .payment-methods {
                button {
                  display: none !important;
                }
              }
            }

            #place-order-trigger-wrapper {
              position: relative;

              &:not(.tooltip-active) {
                .tooltip {
                  display: none;
                }

                button {
                  &:hover {
                    text-indent: -50px;

                    &::after {
                      transform: none;
                    }
                  }
                }
              }

              &.tooltip-active {
                .tooltip {
                  display: flex;
                  position: absolute;
                  top: -100%;
                  left: 50%;
                  flex-wrap: wrap;
                  place-content: center center;
                  width: calc(100% - 20px);
                  height: 50px;
                  transform: translate(-50%, 0);
                  transition: 300ms ease-in-out transform;
                  opacity: 0;
                  background: #002e54d6;
                  color: white;

                  &::after {
                    content: "";
                    position: absolute;
                    bottom: -5px;
                    left: 50%;
                    width: 0;
                    height: 0;
                    margin-left: -2px;
                    border-top: 5px solid #002e54d6;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                  }

                  p {
                    display: inline;
                    margin: 0 5px 0 0;
                  }
                }

                &:hover {
                  .tooltip {
                    display: flex;
                    transform: translate(-50%, -10px);
                    opacity: 1;
                  }
                }
              }

              button {
                position: relative;
                width: 100%;
                height: 50px;
                overflow: hidden;
                transition: 300ms ease-in-out all;
                border: none;
                background-color: $orange;
                font-size: 18px;
                text-indent: 0;

                &::after {
                  content: "\e905";
                  display: flex;
                  position: absolute;
                  top: 0;
                  right: 0;
                  align-items: center;
                  justify-content: center;
                  width: 50px;
                  height: 100%;
                  transform: translateX(50px);
                  transition: 300ms ease-in-out transform;
                  background: $blue;
                  font-family: icomoon;
                  font-size: 24px;
                  text-indent: 0;
                }
              }
            }

            .actions-toolbar {
              width: 100%;
              margin: 0;

              .primary:not(button) {
                width: 100%;
                padding: 0;
              }

              button {
                width: 100%;
                height: 50px;
                border: none;
                background-color: $orange;
              }
            }

            .checkout-block {
              margin: 20px 10px;
              padding: 30px;
              border: 1px solid $grey;
              box-shadow: none;

              .loading-mask {
                background: none;
              }

              @include media(mobile, tabletportrait) {
                padding: 20px;
              }

              @include tabletlandscape {
                padding: 20px;
              }

              @include mobile {
                margin-right: 0;
                margin-left: 0;
                border: none;
              }

              &:hover {
                box-shadow: none;
              }

              #checkout-step-shipping_method {
                .table-checkout-shipping-method {
                  .amcheckout-items {
                    padding-bottom: 0;
                  }

                  tr {
                    padding-bottom: 0;

                    &.amcheckout-method {
                      display: flex;
                      flex-direction: row;
                    }

                    td {
                      display: flex;
                      align-items: center;
                      padding-top: 5px;
                      padding-bottom: 5px;
                      border-top: 0;
                      vertical-align: middle;

                      &.col-carrier {
                        display: none;
                      }

                      &.col-price {
                        order: 3;
                      }

                      &.col-method {
                        width: 100%;
                      }

                      &:first-child {
                        width: 30px;
                      }

                      &.col-error {
                        width: 100%;
                      }
                    }
                  }
                }

                .ampickup-store-container {
                  .ampickup-field {
                    display: flex;
                    flex-direction: column;

                    .ampickup-wrapper {
                      position: relative;
                      flex-direction: row-reverse;
                      margin-bottom: 10px;

                      &::after {
                        content: "\e906";
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translateY(-50%);
                        font-family: icomoon;
                        font-size: 9px;
                        pointer-events: none;
                      }

                      select {

                      }

                      .ampickup-choosemap {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        margin-right: 20px;
                        font-weight: 700;
                      }

                      .ampickup-separator {
                        display: none;
                      }
                    }
                  }
                }
              }

              .opc-block-summary {
                margin-top: 0;
                padding-top: 0;
                background: white;
              }

              .opc-payment {
                .amcheckout-payment-methods {
                  .payment-method-content {
                    padding: 0;
                  }
                }
              }

              .checkout-billing-address {
                max-width: none;
                margin: 0;

                .actions-toolbar {
                  .primary {
                    display: flex;

                    .action {
                      &.action-cancel {
                        flex: 1;
                        order: 1;
                        margin-right: 10px;
                        background: $darkgrey;
                      }

                      &.action-update {
                        flex: 2;
                        order: 2;
                        margin: 0;
                        background: $brightblue;
                      }
                    }
                  }
                }
              }

              .step-title {
                margin-bottom: 0;
                padding-bottom: 10px;
                border-bottom: 0;
                font-weight: 800;
              }

              .additional-options {
                margin: 0 0 10px;

                .amcheckout-checkboxes {
                  >div {
                    display: flex;
                    align-items: center;
                  }

                  label {
                    margin-left: 10px;
                  }
                }
              }

              .payment-methods {
                margin: 0;
              }

              .checkout-payment-method {
                .step-title {
                  padding: 0;
                }

                .payment-option {
                  margin: 0;

                  &:not(.giftcardaccount) {
                    .payment-option-title {
                      padding-top: 0;
                    }
                  }
                }

                .amcheckout-wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  gap: 10px;
                  margin-top: 10px;
                }

                .payment-method {
                  flex: 1;
                  min-width: 40%;
                  max-width: calc(50% - 5px);
                  margin: 0;
                  padding: 0 10px;
                  border: 1px solid transparent;
                  border-radius: 3px;
                  background: #f0f0f0;

                  &._active {
                    border: 1px solid $green;
                    background: #e8fbf1;

                    .payment-method-content {
                      display: none;
                    }

                    label {
                      cursor: default !important;
                    }
                  }

                  &.zipmoney {
                    .payment-method-title {
                      img {
                        max-width: 70px;
                        transform: translateY(-4px);
                      }
                    }
                  }

                  &#afterpay {
                    .afterpay-checkout-note {
                      .afterpay-checkout-note-header {
                        h3 {
                          color: $black;
                          text-align: center;
                        }
                      }
                    }

                    ul {
                      margin: 0;

                      li {
                        color: $black;
                        font-weight: 700;
                      }
                    }

                    .afterpay {
                      background: transparent;

                      .terms-conditions {
                        width: 100%;
                        margin-bottom: 10px;
                      }
                    }
                  }

                  &.payment-method-braintree {
                    .payment-method-title {
                      >label {
                        span {
                          display: flex;
                          align-items: center;
                        }

                        ::before {
                          content: "";
                          display: inline-block;
                          width: 90px;
                          height: 40px;
                          margin-right: 5px;
                          background-image: url("../images/payment-methods/creditcard.svg");
                          background-repeat: no-repeat;
                          background-position: center;
                          background-size: contain;
                        }
                      }
                    }
                  }

                  .payment-method-title {
                    display: flex;
                    align-items: center;
                    height: 60px;
                    margin: 0;
                    border: 0;

                    input {
                      order: -1;
                      min-width: 26px;
                      cursor: pointer;
                    }

                    label {
                      display: flex;
                      align-items: center;
                      order: 2;
                      width: 100%;
                      margin-left: 15px;
                      padding: 10px 0;
                      cursor: pointer;

                      @include tabletlandscape {
                        margin-left: 10px;
                      }
                    }

                    span {
                      font-size: 0;
                    }

                    img {
                      width: 100%;
                      min-width: 70px;
                      max-width: 120px;
                      height: auto !important;
                      max-height: 50px;
                      object-fit: contain;
                      object-position: left;
                    }

                    > img {
                      position: absolute;
                      left: 20px;
                      order: 1;
                      margin-right: 0;
                      margin-left: 20px;
                      pointer-events: none;

                      @include tabletlandscape {
                        margin-left: 10px;
                      }
                    }
                  }

                  &.payment-method-braintree {
                    &._active {
                      min-width: 100%;

                      .payment-method-content {
                        display: block;
                        padding-bottom: 10px;
                      }
                    }

                    #payment_form_braintree {
                      margin-bottom: 0;

                      > .legend {
                        + br {
                          display: none;
                        }
                      }

                      .credit-card-types {
                        margin-bottom: 0;
                      }

                      .field {
                        width: calc(50% - 5px);
                        margin-bottom: 0;
                        float: left;

                        .control {
                          .hosted-control {
                            width: 100%;
                            max-width: 520px;
                            height: 40px;
                            border-radius: 0;
                            border-color: $grey;
                            background: white;
                          }

                          >img {
                            width: 40px;
                          }
                        }

                        &.cvv {
                          width: calc(50% - 5px);
                          max-width: none;
                          margin-left: 10px;
                          float: left;

                          .field-tooltip {
                            top: 3px;
                            right: 10px;
                          }

                          .control {
                            padding-right: 0;
                          }
                        }

                        &:first-of-type {
                          width: 100%;
                          float: left;
                        }
                      }
                    }
                  }

                  &#payment-method-braintree-cc-vault {
                    min-width: 100%;

                    &._active {
                      min-width: 100%;
                    }

                    span {
                      display: inline-flex;
                      align-items: center;
                      font-size: 14px;
                    }

                    img {
                      min-width: 40px;
                      max-width: 40px;
                    }
                  }
                }

                #co-payment-form > .fieldset > *:not(#checkout-payment-method-load):not(.payment-option) {
                  display: none;
                }
              }

              ._block-content-loading {
                title span,
                .table-totals tr .mark,
                .table-totals tr .price,
                .table-totals tr .price.is-free,
                .table-totals tr .mark .value,
                .amount span,
                .product-item-name-block .product-item-name,
                .details-qty .label,
                .details-qty .qty,
                .product-item .delete,
                .product-image-container .product-image-wrapper,
                .items-in-cart .title strong,
                .product-item-inner .subtotal .cart-price .price,
                .amcheckout-items tr.row input[type="radio"],
                .amcheckout-items tr.row .price {
                  animation: loading 1.5s infinite;
                  border: transparent;
                  opacity: 1;
                  background:
                    linear-gradient(0.25turn, transparent, #fff, transparent),
                    linear-gradient($grey, $grey),
                    radial-gradient(38px circle at 19px 19px, $grey 50%, transparent 51%),
                    linear-gradient($grey, $grey);
                  background-repeat: no-repeat;
                  background-position: -315px 0, 0 0, 0 190px, 50px 195px;
                  background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
                  color: transparent;
                  cursor: progress;
                }

                .amcheckout-items tr.row .col-method,
                .amcheckout-items tr.row .col-carrier {
                  opacity: 0;
                }

                .product-image-container .product-image-wrapper img {
                  opacity: 0;
                }
              }

              #shipping {
                #checkout-step-shipping {
                  form {
                    margin: 0;
                    margin-bottom: 20px;

                    .fieldset {
                      margin: 0;

                      div[name$=".firstname"],
                      div[name$=".region_id"] {
                        width: calc(50% - 2px);
                        padding-right: 10px;
                      }

                      div[name$=".region_id"] {
                        .control {
                          position: relative;

                          &::after {
                            content: "\e906";
                            position: absolute;
                            top: 15px;
                            right: 5px;
                            margin-right: 5px;
                            font-family: icomoon;
                            font-size: 8px;
                            pointer-events: none;
                          }
                        }
                      }
                    }
                  }

                  .field-select-billing {
                    margin-top: 20px;
                    margin-bottom: 10px;

                    label {
                      display: block;
                      width: 100%;
                      font-size: 26px;
                      font-weight: 800;
                      text-align: left;
                    }

                    .control {
                      position: relative;
                      width: 100%;
                      margin-top: 20px;

                      &::after {
                        content: "\e906";
                        position: absolute;
                        top: 15px;
                        right: 5px;
                        margin-right: 5px;
                        font-family: icomoon;
                        font-size: 8px;
                        pointer-events: none;
                      }

                      select {
                        height: 40px;
                        font-family: Nunito;
                        appearance: none;
                      }
                    }
                  }

                  .billing-address-same-as-shipping-block {
                    margin-top: 20px;
                  }

                  .amcheckout-wrapper {
                    .checkout-shipping-address {
                      margin-bottom: 20px;
                    }

                    button {
                      min-width: 130px;
                      margin-top: 10px;
                      margin-right: 0;
                      margin-left: 0;
                    }
                  }
                }
              }
            }

            .table-totals {
              display: flex;
              width: calc(100% + 60px);
              max-width: none;
              margin-right: -30px;
              margin-left: -30px;
              padding: 20px 30px;
              background: $blue;
              color: white;

              @include media(mobile, tabletportrait, tabletlandscape) {
                width: calc(100% + 40px);
                margin-right: -20px;
                margin-left: -20px;
                padding: 20px;
              }

              tbody {
                width: 100%;
              }

              tr {
                display: flex;
                width: 100%;

                td,
                th {
                  padding-top: 2px;
                  padding-bottom: 2px;
                }

                td,
                tr {
                  flex: 1;
                }

                .mark {
                  padding-left: 0;
                  font-weight: 500;

                  .value {
                    color: white;
                    font-weight: 500;
                  }
                }

                .price {
                  padding-right: 0;
                  font-weight: 500;

                  &.is-free {
                    padding: 2px 5px;
                    background: $red;
                  }

                  &[class*="$0.00"] {
                    font-size: 0;

                    &::before {
                      content: "FREE";
                      padding: 0 3px;
                      background: $red;
                      font-size: 14px;
                    }
                  }
                }

                &.totals.sub {
                  >th,
                  >td {
                    padding-top: 0;
                  }
                }

                &.grand.totals {
                  >th,
                  >td {
                    padding-top: 5px;
                    padding-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;

                    .price {
                      font-weight: 700;
                    }

                    strong {
                      font-size: 18px;
                      font-weight: 700;
                    }
                  }
                }

                &.shipping {
                  .mark {
                    .label {
                      display: none;
                    }
                  }
                }
              }
            }

            #timer-default {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              width: calc(100% + 60px);
              max-width: none;
              margin: 0 -30px;
              padding: 20px 30px;
              background: $lightblue;

              @include media(mobile, tabletportrait, tabletlandscape) {
                width: calc(100% + 40px);
                margin-right: -20px;
                margin-left: -20px;
                padding: 20px;
              }

              .amcountdown-text {
                flex: 1;
                width: 100%;
                min-width: 210px;
                margin-bottom: 0;
                padding-right: 20px;

                @include media(mobile, tabletportrait, tabletlandscape) {
                  padding-right: 0;
                }

                &.-after {
                  display: none;
                  margin-top: 0;
                  margin-bottom: 0;
                }

                h1,
                h2,
                h3 {
                  margin: 0 0 5px;
                  color: $blue;
                  font-size: 16px;
                  font-weight: 700;
                }

                p {
                  margin-bottom: 0;
                  color: $black;
                  font-size: 11px;
                  font-weight: 300;
                  line-height: 13px;
                }
              }

              .amcountdown-content {
                flex: 1;
                align-items: center;
                justify-content: flex-start;
                width: 50%;
                min-width: 210px;
                margin-bottom: 0;

                .amcountdown-link {
                  display: none;
                }

                .amcountdown-list {
                  position: relative;
                  margin: 20px 0 0;

                  @include media(mobile, tabletportrait, tabletlandscape) {
                    margin-top: 30px;
                  }

                  &::before {
                    content: "Ends in:";
                    position: absolute;
                    top: -20px;
                    width: 100%;
                    font-size: 11px;
                    font-weight: 700;
                  }

                  .amcountdown-wrap {
                    .amcountdown-item {
                      margin-bottom: 0;
                      padding: 0;
                      background: white;

                      span {
                        padding: 0 0 3px;
                        color: $black;
                        line-height: 11px;

                        &.amcountdown-number {
                          padding: 3px 0 0;
                          font-weight: 800;
                          line-height: 18px;

                          &::after {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .payment-option-title {
              padding: 20px 0;

              span {
                color: $brightblue;
                text-decoration: underline;
              }
            }

            .payment-option-content {
              padding: 0;

              .form {
                display: flex;
                max-width: 520px;
                margin-right: 0;
                padding: 0;

                >div {
                  &.payment-option-inner {
                    flex: 2;

                    .field {
                      margin-top: 0;
                    }
                  }

                  &.actions-toolbar {
                    flex: 1;

                    .primary {
                      padding-left: 10px;

                      .action {
                        height: 40px;
                        background: $brightblue;
                        color: white;
                      }
                    }

                    .secondary {
                      .action {
                        height: 40px;
                        padding: 0;
                        background: transparent;
                        color: $black;
                        font-weight: 500;
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
            }

            .items-in-cart {
              margin-bottom: 0;

              .title {
                > strong {
                  >span {
                    font-size: 14px;
                    font-weight: 700;
                  }
                }
              }

              .minicart-items {
                padding-top: 0;

                .minicart-items-wrapper {
                  padding: 0;

                  .minicart-items {
                    .product-item {
                      padding: 10px 0;

                      .product {
                        display: flex;

                        .delete {
                          display: flex;
                          position: absolute;
                          z-index: 1;
                          right: 0;
                          bottom: 10px;
                          align-items: center;
                          justify-content: center;
                          width: 30px;
                          height: 30px;
                          padding: 0;
                          float: none;
                          border: none;
                          background: none;
                          font-size: 18px;

                          &::before {
                            content: "\e9ac";
                            font-family: icomoon;
                          }

                          &:hover {
                            &::before {
                              color: $red;
                            }
                          }
                        }

                        .product-image-container {
                          min-width: 100px;
                          margin-left: 0;

                          .product-image-wrapper {
                            img {
                              font-size: 0;
                            }
                          }
                        }

                        .product-item-details {
                          position: relative;
                          padding: 0 10px;

                          button.action.primary.checkout {
                            position: absolute;
                            bottom: 6px;
                            left: 100px;
                          }

                          .product-item-inner {
                            .product-item-name-block {
                              .product-item-name {
                                font-weight: 500;
                              }

                              .details-qty {
                                .label {
                                  span {
                                    font-size: 11px;
                                    font-weight: 700;
                                  }
                                }
                              }
                            }

                            .subtotal {
                              .cart-price {
                                .price {
                                  font-size: 14px;
                                  font-weight: 700;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
