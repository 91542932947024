@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

body {
  overflow-x: hidden;
  font-family: Nunito, sans-serif;
  font-weight: 500;

  &.checkout-index-index {
    padding-top: 60px;

    @include mobile {
      padding-top: 60px;
    }

    .message.global.demo {
      position: relative;
      z-index: 1;
    }

    .message.notice {
      width: fit-content;
      padding-left: 50px;
      font-size: 14px;

      > *:first-child {
        position: initial;

        &::before {
          top: 50%;
          left: 8px;
          margin-top: 0;
          transform: translateY(-50%);
        }
      }
    }

    .page-bottom {
      z-index: 1;
    }

    #timer-default {
      display: none;
    }

    .page-header {
      .header.content {
        align-items: flex-start;
        max-width: 1600px;
        height: 60px;
        margin: 0 auto;
        padding: 0 20px;

        @include tabletportrait {
          padding: 0 10px;
        }

        @include mobile {
          max-width: 630px;
          padding: 0 15px;
        }

        #return {
          position: relative;

          a {
            display: flex;
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 0;
            align-items: center;
            justify-content: center;
            width: max-content;
            min-width: 70px;
            height: 40px;
            background: transparent;
            color: white;
            font-weight: 700;

            &::before {
              content: "\e906";
              margin-right: 5px;
              transform: rotate(90deg);
              font-family: icomoon;
              font-size: 5px;
            }

            &:hover {
              color: white;
              text-decoration: none;

              &::before {
                color: white;
              }
            }
          }
        }

        .logo {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: 0 auto;
          padding: 0;
          transform: translate(-50%, -50%);

          img {
            position: relative;
            max-width: 120px;
            transform: none;
          }
        }
      }
    }

    #timer-default {
      display: none;
    }

    .page-wrapper {
      #store-notification {
        z-index: 1;
      }

      .page-main {
        padding: 0 30px;

        @include mobile {
          padding: 0 15px;
        }

        .page-title-wrapper {
          padding-top: 30px;
        }

        .columns {
          .column {
            @include mobile {
              padding-bottom: 0;
            }

            #checkout {
              .authentication-wrapper {
                display: none;
                position: fixed;
                z-index: 9999;
                top: 10px;
                right: 30px;
                margin-top: 0;

                @include mobile {
                  right: 10px;
                }
              }

              .opc-progress-bar {
                display: flex;
                max-width: 320px;
                height: 40px;
                margin-top: 20px;
                margin-bottom: 20px;
                overflow: hidden;

                @include mobile {
                  max-width: 600px;
                  margin: 20px auto;
                }

                li.opc-progress-bar-item {
                  display: inline-flex;
                  position: relative;
                  align-items: center;
                  width: auto;
                  padding-right: 0;

                  &::before {
                    display: none;
                  }

                  &::after {
                    content: "\e608";
                    display: inline-block;
                    margin: 0;
                    font-family: icons-blank-theme;
                    font-size: 24px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-weight: normal;
                    line-height: 18px;
                    text-align: center;
                    vertical-align: top;
                    speak: none;
                  }

                  &:last-child {
                    &::after {
                      display: none;
                    }
                  }

                  > span,
                  > a {
                    position: relative;
                    z-index: 1;
                    padding-top: 0;
                    color: $grey;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 40px;

                    &::before {
                      display: none;
                    }

                    &::after {
                      display: none;
                    }
                  }

                  &._complete {
                    span,
                    a {
                      color: $black;
                      font-weight: 500;
                    }

                    &:hover {
                      span,
                      a {
                        text-decoration: underline;
                      }
                    }
                  }

                  &._active {
                    span {
                      color: $black;
                      font-weight: 500;
                    }
                  }

                  &:last-child {
                    &._active {
                      &::after {
                        display: none;
                      }
                    }
                  }
                }
              }

              .messages {
                .message {
                  max-width: 500px;
                }
              }

              .opc-estimated-wrapper {
                display: none;
              }

              .opc-wrapper {
                width: 50%;

                @include mobile {
                  width: 100%;
                  margin-bottom: 50px;
                  padding: 0;
                }

                .opc {
                  display: flex;
                  flex-direction: column;
                  max-width: 600px;

                  &#checkoutSteps {
                    position: relative;
                    padding-bottom: 70px;
                  }

                  @include mobile {
                    margin-right: auto;
                    margin-left: auto;
                  }

                  li {
                    &#store-pickup {
                      &.selected-shipping {
                        display: none;
                      }

                      #delivery-method-selector {
                        display: flex;
                        margin-bottom: 0;
                        gap: 15px;

                        .action {
                          position: relative;
                          padding: 25px 25px 25px 15px;
                          border: 1px solid $grey;
                          border-radius: 5px;
                          background: transparent;
                          color: $black;
                          font-size: 14px;
                          line-height: 14px;

                          &:hover {
                            background: white;
                          }

                          &:active {
                            box-shadow: none;
                          }

                          &::before {
                            content: "";
                            display: inline-block;
                            width: 26px;
                            height: 26px;
                            margin-right: 5px;
                            border: 1px solid $grey;
                            border-radius: 13px;
                            background: white;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                          }

                          &::after {
                            content: "\ea10";
                            display: flex;
                            position: absolute;
                            left: 20px;
                            align-items: center;
                            justify-content: center;
                            width: 18px;
                            height: 18px;
                            transform: scale(0);
                            transition: 120ms transform ease-in-out;
                            border-radius: 50%;
                            background: $green;
                            box-shadow: none;
                            color: white;
                            font-family: icomoon;
                            font-size: 12px;
                          }

                          &.selected {
                            z-index: 1;
                            border: 1px solid $green;
                            background: #ecf7f1;

                            &::after {
                              transform: scale(1);
                            }
                          }

                          &.action-select-shipping {
                            &::before {
                              background-image: url("../images/static-blocks/usp-shipping.svg");
                            }
                          }

                          &.action-select-store-pickup {
                            &::before {
                              background-image: url("../images/static-blocks/usp-pickup.svg");
                            }
                          }
                        }
                      }

                      #store-selector {
                        margin-top: 30px;

                        .form-login {
                          margin-top: 0;

                          .actions-toolbar {
                            display: flex;
                            margin-top: 10px;
                            margin-left: 0;

                            .primary {
                              margin-bottom: 0;

                              .login {
                                display: flex;
                                width: 160px;
                                height: 50px;
                              }
                            }

                            .secondary {
                              display: flex;
                              align-items: center;
                              margin-left: 10px;

                              a {
                                margin-top: 0;
                              }
                            }
                          }

                          .note {
                            color: $darkgrey;
                            font-size: 10px;

                            a {
                              color: $darkgrey;
                              text-decoration: underline;
                            }
                          }
                        }

                        #checkout-step-store-selector {
                          margin-bottom: 0;

                          .pickup-location {
                            margin-bottom: 30px;
                            padding: 15px;
                            border: 1px solid $green;
                            border-radius: 5px;
                            background: #ecf7f1;

                            .location-name {
                              margin-bottom: 0;
                            }
                          }

                          .location-description {
                            strong {
                              font-family: Nunito, sans-serif !important;
                            }

                            ul {
                              margin-bottom: 0;
                              padding-left: 0;
                              list-style: none;

                              li {
                                position: relative;
                                padding-left: 20px;
                                font-weight: 500;

                                &::before {
                                  content: "\ea10";
                                  position: absolute;
                                  left: 0;
                                  margin-right: 5px;
                                  color: $green;
                                  font-family: icomoon;
                                }

                                strong {
                                  font-family: Nunito, sans-serif;
                                  font-size: 14px;
                                  font-weight: 500;
                                }
                              }
                            }
                          }

                          .selected-location-details {
                            margin-top: 10px;
                          }

                          .no-selected-location {
                            margin-top: 10px;
                          }

                          .form-continue {
                            .actions-toolbar {
                              display: flex;
                              flex-wrap: wrap;
                              width: 100%;
                              margin: 0;

                              @include mobile {
                                flex-wrap: wrap;
                              }

                              button {
                                display: flex;
                                height: 50px;
                              }

                              .primary {
                                display: flex;
                                order: 2;
                                width: 100%;
                                padding-top: 15px;
                                border-top: 1px solid $grey;

                                @include mobile {
                                  flex: auto;
                                  width: 100%;
                                }

                                button {
                                  display: flex;
                                  position: relative;
                                  width: 100%;
                                  height: 50px;
                                  overflow: hidden;
                                  transition: 300ms ease text-indent;
                                  border: 0;
                                  background: $orange;

                                  &::after {
                                    content: "\e901";
                                    display: flex;
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    align-items: center;
                                    justify-content: center;
                                    width: 50px;
                                    height: 100%;
                                    transform: translateX(50px);
                                    transition: 300ms ease-in-out transform;
                                    background: $blue;
                                    font-family: icomoon;
                                    font-size: 24px;
                                    text-indent: 0;
                                  }

                                  &:hover {
                                    text-indent: -50px;

                                    &::after {
                                      transform: none;
                                    }
                                  }
                                }
                              }

                              .secondary {
                                display: none;
                                order: 1;
                                width: 100%;
                                padding-bottom: 15px;

                                @include mobile {
                                  flex: auto;
                                  width: 100%;
                                }

                                button {
                                  width: 160px;
                                }
                              }
                            }
                          }

                          .no-selected-location {
                            + .form-continue .actions-toolbar .secondary {
                              display: flex;
                            }
                          }
                        }
                      }
                    }

                    &.checkout-shipping-address {
                      order: 99;
                      margin-top: 0;

                      .step-content {
                        margin-bottom: 0;

                        .shipping-address-items {
                          display: flex;
                          flex-wrap: wrap;
                          width: calc(100% + 10px);
                          padding-bottom: 10px;

                          .shipping-address-item {
                            width: calc(33.3333% - 10px);
                            margin-top: 10px;
                            margin-right: 10px;
                            margin-bottom: 0;
                            padding: 10px 30px 10px 10px;
                            border: 0;
                            border: 1px solid transparent;
                            opacity: 0.3;
                            line-height: 19px;

                            @include tabletlandscape {
                              width: calc(50% - 10px);
                            }

                            @include tabletportrait {
                              width: calc(50% - 10px);
                            }

                            @include mobile {
                              width: calc(100% - 10px);
                            }

                            &::before {
                              display: none;
                            }

                            &:hover {
                              opacity: 1;
                            }

                            &.selected-item {
                              border-radius: 5px;
                              border-color: $green;
                              opacity: 1;
                              background: #eef8f3;

                              &::after {
                                content: "\ea10";
                                display: flex;
                                top: 10px;
                                right: 10px;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                padding: 0;
                                border-radius: 10px;
                                background: $green;
                                font-family: icomoon;
                                font-size: 11px;
                              }
                            }

                            .action-select-shipping-item {
                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 100%;
                              height: 100%;
                              opacity: 0;
                            }
                          }
                        }
                      }
                    }

                    &.checkout-payment-method {
                      form.payments {
                        fieldset {
                          display: flex;
                          flex-direction: column;

                          .field-select-billing {
                            margin-top: 0;

                            select[name="billing_address_id"] {
                              margin-bottom: 15px;
                              padding-top: 5px;
                            }

                            .label {
                              position: relative;
                              height: auto;
                              font-size: 24px;
                            }
                          }

                          >br {
                            display: none;
                          }

                          >hr {
                            display: none;
                          }

                          .opc-payment {
                            margin: 0;

                            .no-payments-block {
                              color: $black;
                            }

                            .payment-methods {
                              margin: 0;

                              .payment-group {
                                max-height: 0;
                                overflow: hidden;
                                transition: 300ms ease-in-out all;
                                opacity: 0.5;
                                pointer-events: none;

                                &.active,
                                &.visible {
                                  max-height: 1000px;
                                  opacity: 1;
                                  pointer-events: all;
                                }

                                .step-title {
                                  margin-top: 30px;

                                  @include mobile {
                                    margin-right: 0;
                                    margin-left: 0;
                                  }
                                }

                                .payment-method {
                                  margin-bottom: 10px;
                                  padding-right: 15px;
                                  padding-left: 15px;
                                  border-radius: 5px;

                                  &:hover {
                                    background: white;
                                  }

                                  &#payment-method-braintree-applepay {
                                    .payment-method-title {
                                      label {
                                        &::after {
                                          background-image: url("../images/payment-methods/applepay.svg");
                                        }
                                      }
                                    }

                                    .payment-method-content {
                                      .actions-toolbar {
                                        .braintree-apple-pay-button {
                                          width: 100%;
                                          height: 50px;
                                        }
                                      }
                                    }
                                  }

                                  &.zipmoney {
                                    .payment-method-title {
                                      label {
                                        &::after {
                                          background-image: url("../images/payment-methods/zippay.svg");
                                        }
                                      }

                                      img {
                                        max-width: 70px;
                                        transform: translateY(-4px);
                                      }
                                    }
                                  }

                                  &.afterpay {
                                    .payment-method-title {
                                      label {
                                        &::before {
                                          content: "Afterpay";
                                        }

                                        &::after {
                                          background-image: url("../images/payment-methods/afterpay.svg");
                                        }
                                      }
                                    }

                                    .afterpay-checkout-note {
                                      .afterpay-checkout-note-header {
                                        h3 {
                                          color: $black;
                                          text-align: center;
                                        }
                                      }
                                    }

                                    .actions-toolbar-wraper {
                                      padding: 0;
                                      background: transparent;
                                    }

                                    ul {
                                      margin: 0;

                                      li {
                                        color: $black;
                                        font-weight: 700;
                                      }
                                    }

                                    .afterpay {
                                      background: transparent;

                                      .terms-conditions {
                                        width: 100%;
                                        margin-bottom: 10px;
                                      }
                                    }
                                  }

                                  &#payment-method-braintree-paypal {
                                    .payment-method-title {
                                      label {
                                        &::after {
                                          background-image: url("../images/payment-methods/paypal.svg");
                                        }
                                      }
                                    }

                                    &._active {
                                      .braintree-paypal-fieldset {
                                        padding: 0;
                                        border: 0;
                                      }

                                      .actions-toolbar.braintree-paypal-actions {
                                        margin-left: 0;

                                        .payment-method-item {
                                          display: none;
                                        }
                                      }
                                    }
                                  }

                                  &.payment-method-braintree {
                                    .payment-method-title {
                                      >label {
                                        span {
                                          display: flex;
                                          align-items: center;
                                        }

                                        &::after {
                                          background-image: url("../images/payment-methods/creditcard.svg");
                                        }
                                      }
                                    }
                                  }

                                  &.openpay {
                                    .payment-method-title {
                                      >label {
                                        >span {
                                          display: flex;
                                          align-items: center;
                                          font-size: 0;

                                          &::after {
                                            content: "Buy now. Pay smarter";
                                            margin-right: 5px;
                                            font-size: 14px;
                                          }

                                          span {
                                            display: none;
                                          }
                                        }
                                      }
                                    }
                                  }

                                  .payment-method-title {
                                    min-height: 55px;
                                    margin: 0;
                                    padding: 0;
                                    border: 0;

                                    .radio {
                                      min-width: 26px;
                                      margin-right: 10px;
                                    }

                                    label {
                                      display: flex;
                                      align-items: center;
                                      order: 2;
                                      width: 100%;
                                      margin-left: 5px;
                                      padding: 10px 0;
                                      cursor: pointer;

                                      img {
                                        display: none;
                                      }

                                      &::after {
                                        content: "";
                                        display: inline-block;
                                        order: 99;
                                        width: 90px;
                                        height: 40px;
                                        margin-right: 5px;
                                        margin-left: auto;
                                        border-radius: 5px;
                                        background-color: white;
                                        background-repeat: no-repeat;
                                        background-position: center;
                                        background-size: contain;
                                      }

                                      @include tabletlandscape {
                                        margin-left: 10px;
                                      }
                                    }

                                    img {
                                      order: 99;
                                      width: auto;
                                      min-width: 70px;
                                      max-width: 120px;
                                      height: auto !important;
                                      max-height: 50px;
                                      margin-left: auto;
                                      object-fit: contain;
                                      object-position: left;
                                    }

                                    > img {
                                      order: 1;
                                      margin-right: 0;
                                      margin-left: 5px;
                                      padding: 5px 0;
                                      pointer-events: none;

                                      @include tabletlandscape {
                                        margin-left: 10px;
                                      }
                                    }
                                  }

                                  &._active {
                                    padding-bottom: 15px;
                                    border: 1px solid $green;
                                    background: #e8fbf1;

                                    .payment-method-title {
                                      label {
                                        &::after {
                                          background-color: transparent;
                                        }
                                      }
                                    }

                                    .billing-address-details {
                                      margin-bottom: 15px;
                                      padding-left: 0;
                                      line-height: 19px;

                                      a {
                                        color: $black;
                                      }

                                      button {
                                        height: auto;
                                        margin-top: 10px;
                                        padding: 0;
                                        background: none;
                                        color: $black;
                                        font-size: 14px;
                                        font-weight: 700;
                                        text-decoration: underline;
                                      }
                                    }

                                    .billing-address-form {
                                      margin-bottom: 15px;

                                      .fieldset {
                                        > .field {
                                          margin-bottom: 10px;

                                          >label {
                                            display: none;
                                          }
                                        }
                                      }
                                    }

                                    .payment-method-content {
                                      padding: 0;

                                      .learn-more {
                                        display: inline-block;
                                        margin-bottom: 10px;
                                        color: $brightblue;
                                        font-size: 14px;

                                        opy-learn-more-button {
                                          color: $brightblue;
                                          font-size: 14px;
                                          font-weight: 500;
                                        }
                                      }

                                      .op-checkout-widget {
                                        width: 100%;
                                        max-width: 340px;
                                      }

                                      >.field {
                                        margin-bottom: 10px;
                                      }

                                      form {
                                        .fieldset {
                                          display: block;
                                          margin: 0;
                                        }

                                        .field {
                                          &.hide {
                                            display: none;
                                          }

                                          &[name="shippingAddress.country_id"] {
                                            display: none;
                                          }

                                          &[name="shippingAddress.region_id"] {
                                            .control {
                                              position: relative;

                                              &::after {
                                                content: "\e906";
                                                position: absolute;
                                                top: 20px;
                                                right: 15px;
                                                font-family: icomoon;
                                                font-size: 8px;
                                                pointer-events: none;
                                              }
                                            }
                                          }

                                          &[name="shippingAddress.manual"] {
                                            > label {
                                              display: none;
                                            }

                                            .field {
                                              display: flex;
                                              align-items: center;
                                              justify-content: flex-end;
                                              margin-top: 0;

                                              > label {
                                                width: auto;
                                                padding: 0;
                                              }

                                              > input {
                                                position: relative;
                                                order: 1;
                                                width: 44px;
                                                height: 26px;
                                                margin-right: 0;
                                                margin-left: 5px;
                                                transition: 300ms ease-in-out background-color;
                                                border: none;
                                                border: 1px solid $grey;
                                                border-radius: 15px;
                                                background: white;
                                                cursor: pointer;

                                                &::before {
                                                  content: "";
                                                  display: block;
                                                  position: absolute;
                                                  top: 3px;
                                                  left: 3px;
                                                  width: 18px;
                                                  height: 18px;
                                                  transform: none;
                                                  transition: 300ms ease-in-out all;
                                                  border-radius: 10px;
                                                  background: $grey;
                                                }

                                                &:checked {
                                                  &::before {
                                                    transform: translateX(100%);
                                                    background: $green;
                                                  }
                                                }
                                              }
                                            }
                                          }

                                          &[name*="firstname"],
                                          &[name*="lastname"] {
                                            display: inline-block;
                                            width: 50%;
                                          }

                                          &[name*="firstname"] {
                                            padding-right: 10px;
                                          }
                                        }

                                        &#co-transparent-form-braintree {
                                          #payment_form_braintree {
                                            > div.field {
                                              &:nth-of-type(2),
                                              &:nth-of-type(3) {
                                                display: inline-block;
                                                width: 50%;
                                                padding-right: 10px;

                                                @include mobile {
                                                  display: block;
                                                  width: 100%;
                                                  padding-right: 0;
                                                }
                                              }
                                            }
                                          }

                                          .cvv {
                                            .field-tooltip {
                                              top: 3px;
                                            }
                                          }

                                          .braintree-credit-card-selected {
                                            height: 40px;
                                          }

                                          .hosted-control {
                                            width: 100%;
                                            max-width: none;
                                            height: 40px;
                                            background: white;
                                          }
                                        }
                                      }

                                      .actions-toolbar {
                                        width: 100%;
                                        margin: 0;

                                        .primary {
                                          width: 100%;

                                          .checkout {
                                            display: flex;
                                            position: relative;
                                            width: 100%;
                                            height: 50px;
                                            border: 0;
                                            opacity: 1;
                                            background: $orange;
                                            cursor: pointer;
                                            pointer-events: all;

                                            &:not(.disabled) {
                                              display: flex;
                                              position: relative;
                                              width: 100%;
                                              height: 50px;
                                              overflow: hidden;
                                              transition: 300ms ease text-indent;
                                              border: 0;
                                              background: $orange;

                                              &::after {
                                                content: "\e93f";
                                                display: flex;
                                                position: absolute;
                                                top: 0;
                                                right: 0;
                                                align-items: center;
                                                justify-content: center;
                                                width: 50px;
                                                height: 100%;
                                                transform: translateX(50px);
                                                transition: 300ms ease-in-out transform;
                                                background: $blue;
                                                font-family: icomoon;
                                                font-size: 24px;
                                                text-indent: 0;
                                              }

                                              &:hover {
                                                text-indent: -50px;

                                                &::after {
                                                  transform: none;
                                                }
                                              }
                                            }

                                            &.disabled {
                                              &:hover {
                                                &::before {
                                                  content: "Please enter a billing address.";
                                                  display: block;
                                                  display: flex;
                                                  position: absolute;
                                                  z-index: 1;
                                                  top: -60px;
                                                  align-items: center;
                                                  justify-content: center;
                                                  width: 240px;
                                                  height: 50px;
                                                  background: rgb(0 0 0 / 80%);
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  line-height: 19px;
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .checkout-billing-address {
                            order: -1;

                            .actions-toolbar {
                              .action-update {
                                position: relative;
                                overflow: hidden;
                                transition: 300ms ease-in-out text-indent;
                                background-color: $orange;
                                font-size: 18px;

                                &::after {
                                  content: "\e93f";
                                  display: flex;
                                  position: absolute;
                                  top: 0;
                                  right: 0;
                                  align-items: center;
                                  justify-content: center;
                                  width: 50px;
                                  height: 100%;
                                  transform: translateX(50px);
                                  transition: 300ms ease-in-out transform;
                                  background: $blue;
                                  font-family: icomoon;
                                  font-size: 24px;
                                  text-indent: 0;
                                }

                                &:hover {
                                  text-indent: -50px;

                                  &::after {
                                    transform: none;
                                  }
                                }
                              }
                            }

                            &::before {
                              content: "Billing Address";
                              display: block;
                              margin-bottom: 15px;
                              padding-bottom: 10px;
                              border-bottom: 1px solid $grey;
                              font-size: 21px;
                              font-weight: 700;
                            }

                            .billing-address-same-as-shipping-block {
                              margin-top: 0;
                              margin-bottom: 20px;

                              >label {
                                display: block;
                                margin-left: 5px;
                              }
                            }

                            .billing-address-details {
                              margin-top: 10px;
                              padding-left: 0;
                              line-height: 19px;

                              a {
                                color: $black;
                              }

                              button {
                                height: auto;
                                margin-top: 10px;
                                padding: 0;
                                background: none;
                                color: $black;
                                font-size: 14px;
                                font-weight: 700;
                                text-decoration: none;

                                span {
                                  text-transform: lowercase;

                                  &::after {
                                    content: "\e90b";
                                    margin-left: 5px;
                                    font-family: icomoon;
                                    font-size: 12px;
                                  }
                                }

                                &:active {
                                  box-shadow: none;
                                }
                              }
                            }

                            .fieldset {
                              display: flex;
                              flex-flow: row wrap;

                              .billing-address-form {
                                form {
                                  .address {
                                    .field {
                                      &.hide {
                                        display: none;
                                      }

                                      &[name="billingAddressshared.country_id"] {
                                        display: none;
                                      }

                                      &[name="billingAddressshared.region_id"] {
                                        .control {
                                          position: relative;

                                          &::after {
                                            content: "\e906";
                                            position: absolute;
                                            top: 20px;
                                            right: 15px;
                                            font-family: icomoon;
                                            font-size: 8px;
                                            pointer-events: none;
                                          }
                                        }
                                      }

                                      &[name="billingAddressshared.manualbilling"] {
                                        > label {
                                          display: none;
                                        }

                                        .control {
                                          > label {
                                            display: none;
                                          }
                                        }

                                        .field {
                                          display: flex;
                                          align-items: center;
                                          justify-content: flex-end;
                                          margin-top: 0;

                                          > label {
                                            width: auto;
                                            padding: 0;
                                          }

                                          > input {
                                            position: relative;
                                            order: 1;
                                            width: 44px;
                                            height: 26px;
                                            margin-right: 0;
                                            margin-left: 5px;
                                            transition: 300ms ease-in-out background-color;
                                            border: none;
                                            border: 1px solid $grey;
                                            border-radius: 15px;
                                            background: white;
                                            cursor: pointer;

                                            &::before {
                                              content: "";
                                              display: block;
                                              position: absolute;
                                              top: 3px;
                                              left: 3px;
                                              width: 18px;
                                              height: 18px;
                                              transform: none;
                                              transition: 300ms ease-in-out all;
                                              border-radius: 10px;
                                              background: $grey;
                                            }

                                            &:checked {
                                              &::before {
                                                transform: translateX(100%);
                                                background: $green;
                                              }
                                            }
                                          }
                                        }
                                      }

                                      &[name*="firstname"],
                                      &[name*="lastname"] {
                                        display: inline-block;
                                        width: 50%;
                                      }

                                      &[name*="firstname"] {
                                        padding-right: 10px;
                                      }
                                    }

                                    .addressbilling {
                                      margin-bottom: 0;

                                      input {
                                        &:disabled {
                                          background: $lightgrey;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }

                          .payment-option {
                            @include mobile {
                              margin: 0;
                            }

                            &.giftcardaccount {
                              display: none;
                            }

                            .payment-option-title {
                              margin-top: 0;
                              padding-top: 10px;
                              padding-bottom: 10px;
                              border: 0;

                              .action-toggle {
                                position: relative;
                                padding-left: 35px;
                                line-height: 26px;

                                span {
                                  color: $black;
                                  font-weight: 500;
                                }

                                &::before {
                                  content: "";
                                  position: absolute;
                                  left: 0;
                                  width: 24px;
                                  height: 24px;
                                  border: 1px solid $grey;
                                  background: white;
                                }

                                &::after {
                                  content: "\ea10";
                                  display: flex;
                                  position: absolute;
                                  top: 0;
                                  left: 0;
                                  align-items: center;
                                  justify-content: center;
                                  width: 26px;
                                  height: 26px;
                                  transform: scale(0);
                                  transition: 120ms transform ease-in-out;
                                  color: #7ccea3;
                                  font-family: icomoon;
                                  font-size: 18px;
                                }
                              }
                            }

                            .payment-option-content {
                              padding-right: 0;
                              padding-left: 0;

                              form {
                                display: flex;
                                gap: 10px;

                                .payment-option-inner {
                                  flex: 1;
                                  margin: 0;

                                  .field {
                                    margin: 0;
                                  }
                                }

                                &.form-discount {
                                  .payment-option-inner {
                                    flex: 2;

                                    .input-text {
                                      min-height: 40px;
                                      padding-bottom: 10px;
                                      background: white;
                                    }
                                  }

                                  .actions-toolbar {
                                    flex: 1;
                                    min-width: 140px;
                                  }
                                }

                                .actions-toolbar {
                                  .primary {
                                    button {
                                      border: 0;
                                      background: $orange;
                                    }
                                  }

                                  .secondary {
                                    button {
                                      margin-right: 10px;
                                      border: 0;
                                    }
                                  }
                                }

                                &.form-giftcard-account {
                                  .actions-toolbar {
                                    .primary {
                                      width: auto;
                                    }
                                  }
                                }
                              }
                            }

                            &._active {
                              .payment-option-title {
                                .action-toggle {
                                  &::after {
                                    transform: scale(1);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    &.checkout-shipping-method {
                      position: initial;
                      padding: 0;

                      .checkout-shipping-method {
                        position: initial;
                        margin-top: 0;

                        @include mobile {
                          margin-top: 15px;
                        }

                        .methods-shipping {
                          #checkout-shipping-method-load {
                            padding-bottom: 20px;

                            @include mobile {
                              padding-bottom: 10px;
                            }

                            .table-checkout-shipping-method {
                              tr {
                                td {
                                  padding-top: 10px;
                                  padding-bottom: 10px;
                                  border: 0;
                                }
                              }
                            }
                          }

                          .actions-toolbar {
                            .primary {
                              button {
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                margin-top: 0;
                              }
                            }
                          }
                        }
                      }
                    }

                    .step-title {
                      margin-bottom: 15px;
                      padding-bottom: 10px;
                      padding-left: 0;
                      border-bottom: 1px solid $grey;
                      font-size: 21px;
                      font-weight: 700;

                      @include mobile {
                        border-bottom: 1px solid $grey;
                        font-size: 21px;
                      }
                    }

                    .step-content {
                      margin-bottom: 0;

                      @include mobile {
                        margin-bottom: 15px;
                      }
                    }

                    .form {
                      .field {
                        > label {
                          display: none;
                        }
                      }

                      .checkbox {
                        + label {
                          display: block;
                        }
                      }

                      .field-error,
                      .mage-error {
                        padding: 5px 0;
                        background: transparent;
                        color: $red;
                      }

                      .input-text,
                      .select {
                        min-height: 50px;
                        padding-top: 10px;
                        padding-left: 15px;
                        border-radius: 5px;

                        ~ label {
                          display: block;
                          position: absolute;
                          top: 15px;
                          left: 16px;
                          height: auto;
                          clip: auto;
                          transition: 300ms ease-in-out all;
                          color: $darkgrey;
                          font-size: 14px;
                          font-weight: 500;
                          pointer-events: none;
                        }

                        &:not(:placeholder-shown) {
                          border-color: $green;
                        }

                        &:focus ~ label,
                        &:not(:placeholder-shown) ~ label {
                          top: 8px;
                          font-size: 11px;
                        }
                      }

                      .show-address {
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 5px;
                        margin-bottom: 10px;

                        .toggle {
                          position: relative;
                          width: 36px;
                          height: 20px;
                          margin-left: 5px;
                          transition: 300ms ease-in-out background-color;
                          border-radius: 15px;
                          background: $grey;
                          cursor: pointer;

                          &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 2px;
                            left: 2px;
                            width: 16px;
                            height: 16px;
                            transition: 300ms ease-in-out transform;
                            border-radius: 8px;
                            background: white;
                          }
                        }

                        &.active {
                          .toggle {
                            background-color: $brightblue;

                            &::before {
                              transform: translateX(100%);
                            }
                          }
                        }
                      }

                      &.form-login {
                        margin: 10px 0;

                        #customer-email-fieldset {
                          fieldset {
                            .control {
                              position: relative;

                              &::after {
                                content: "\e98f";
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                width: 20px;
                                height: 20px;
                                color: $black;
                                font-family: icomoon;
                                font-size: 12px;
                              }
                            }
                          }
                        }

                        .note {
                          color: $darkgrey;
                          font-size: 10px;

                          a {
                            color: $darkgrey;
                            text-decoration: underline;
                          }
                        }

                        .actions-toolbar {
                          display: flex;
                          margin-top: 10px;
                          margin-left: 0;

                          .primary {
                            flex: 1;
                            margin-bottom: 0;

                            .login {
                              display: flex;
                              width: 160px;
                              height: 50px;
                            }
                          }

                          .secondary {
                            display: flex;
                            flex: 1;
                            align-items: center;
                            margin-left: 10px;

                            a {
                              margin-top: 0;
                            }
                          }
                        }
                      }

                      &.form-shipping-address {
                        margin: 0;

                        .address {
                          display: flex;
                          flex-wrap: wrap;
                          margin-bottom: 0;

                          input {
                            &:disabled {
                              background: $lightgrey;
                            }
                          }
                        }

                        .actions-toolbar {
                          margin-top: 30px;

                          .primary {
                            button {
                              font-size: 18px;
                            }
                          }
                        }

                        .field {
                          &[name*="firstname"],
                          &[name*="lastname"] {
                            display: inline-block;
                            width: 50%;
                          }

                          &[name*="firstname"] {
                            padding-right: 10px;
                          }

                          &.hide {
                            display: none;
                          }

                          &[name="shippingAddress.country_id"] {
                            display: none;
                          }

                          &[name="shippingAddress.region_id"] {
                            .control {
                              position: relative;

                              &::after {
                                content: "\e906";
                                position: absolute;
                                top: 20px;
                                right: 15px;
                                font-family: icomoon;
                                font-size: 8px;
                                pointer-events: none;
                              }
                            }
                          }

                          &[name="shippingAddress.manual"] {
                            .control {
                              > label {
                                display: none;
                              }
                            }

                            .field {
                              display: flex;
                              align-items: center;
                              justify-content: flex-end;
                              margin-top: 0;

                              > label {
                                width: auto;
                                padding: 0;
                              }

                              > input {
                                position: relative;
                                order: 1;
                                width: 44px;
                                height: 26px;
                                margin-right: 0;
                                margin-left: 5px;
                                transition: 300ms ease-in-out background-color;
                                border: none;
                                border: 1px solid $grey;
                                border-radius: 15px;
                                background: white;
                                cursor: pointer;

                                &::before {
                                  content: "";
                                  display: block;
                                  position: absolute;
                                  top: 3px;
                                  left: 3px;
                                  width: 18px;
                                  height: 18px;
                                  transform: none;
                                  transition: 300ms ease-in-out all;
                                  border-radius: 10px;
                                  background: $grey;
                                }

                                &:checked {
                                  &::before {
                                    transform: translateX(100%);
                                    background: $green;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .street {
                        margin-top: 5px;

                        legend {
                          display: none;
                        }

                        .field {
                          margin-top: 0;
                        }
                      }

                      .actions-toolbar {
                        width: 100%;
                        margin-left: 0;

                        .primary {
                          display: flex;
                          width: 100%;

                          .action-update {
                            display: flex;
                            flex: 2;
                            order: 99;
                            height: 50px;
                            background: $orange;
                          }

                          .action-cancel {
                            flex: 1;
                            margin-right: 0;
                          }

                          .continue {
                            display: flex;
                            position: relative;
                            width: 100%;
                            height: 50px;
                            overflow: hidden;
                            transition: 300ms ease text-indent;
                            border: 0;
                            background: $orange;

                            &::after {
                              content: "\e901";
                              display: flex;
                              position: absolute;
                              top: 0;
                              right: 0;
                              align-items: center;
                              justify-content: center;
                              width: 50px;
                              height: 100%;
                              transform: translateX(50px);
                              transition: 300ms ease-in-out transform;
                              background: $blue;
                              font-family: icomoon;
                              font-size: 24px;
                              text-indent: 0;
                            }

                            &:hover {
                              text-indent: -50px;

                              &::after {
                                transform: none;
                              }
                            }

                            @include mobile {
                              width: 100%;
                            }
                          }
                        }
                      }

                      #checkout-shipping-method-load {
                        .table-checkout-shipping-method {
                          width: 100%;
                          min-width: 0;
                          margin-right: 0;

                          thead {
                            display: none;
                          }

                          tbody {
                            @include mobile {
                              padding-bottom: 0;
                            }
                          }

                          tr {
                            cursor: pointer;

                            &:hover {
                              background: #fff;
                            }

                            &.row {
                              display: flex;
                              position: relative;
                              align-items: stretch;
                              margin-bottom: 10px;
                              border-radius: 5px;

                              @include mobile {
                                padding-bottom: 0;
                              }

                              .col {
                                line-height: 24px;

                                &.col-selector {
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-end;
                                  width: 40px;

                                  input {
                                    &[type="radio"] {
                                      z-index: 1;

                                      &:checked {
                                        +label {
                                          display: block;

                                          &::before {
                                            content: "";
                                            position: absolute;
                                            z-index: -1;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 5px;
                                            background: white;
                                            pointer-events: none;
                                          }

                                          &::after {
                                            content: "";
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            border: 1px solid $green;
                                            border-radius: 5px;
                                            background: #7ccea423;
                                            pointer-events: none;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }

                                &.col-carrier {
                                  display: none;
                                }

                                &.col-price {
                                  flex: 1;
                                  order: 1;

                                  .price {
                                    float: right;
                                    font-weight: 700;
                                  }
                                }

                                &.col-method {
                                  display: flex;
                                  flex-direction: column;
                                  align-self: center;

                                  >div {
                                    line-height: 21px;
                                  }

                                  .description {
                                    font-size: 12px;
                                    line-height: 19px;
                                  }
                                }
                              }
                            }

                            &.row-error {
                              td.col-error {
                                width: 100%;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .opc-sidebar {
                position: sticky;
                top: 80px;
                width: 50%;
                margin: 0;
                background: white;

                @include mobile {
                  visibility: visible;
                  position: relative;
                  top: auto;
                  right: auto;
                  left: auto;
                  width: 100%;
                  min-width: calc(100% + 30px);
                  max-width: 600px;
                  margin-right: auto;
                  margin-left: -15px;
                  padding: 20px 0 30px;
                  opacity: 1;
                  background: white;
                }

                &::after {
                  content: "";
                  display: block;
                  position: fixed;
                  z-index: -1;
                  top: 0;
                  left: 50%;
                  width: 50%;
                  height: 100%;
                  background: white;

                  @include mobile {
                    display: none;
                  }
                }

                .modal-inner-wrap {
                  max-width: 660px;
                  margin: 0 auto;
                  transform: none;
                  background: transparent;
                  box-shadow: none;

                  .modal-header {
                    .action-close {
                      display: none;
                    }
                  }

                  .modal-content {
                    #opc-sidebar {
                      .opc-block-summary {
                        display: flex;
                        flex-direction: column;
                        padding: 0 0 0 30px;
                        background: transparent;

                        @include mobile {
                          padding: 0 15px;
                        }

                        .table-totals {
                          position: relative;
                          order: 99;
                          margin-top: 15px;
                          border-top: 1px solid $grey;

                          tbody {
                            display: flex;
                            flex-direction: column;
                          }

                          tr {
                            display: flex;
                            justify-content: space-between;

                            td {
                              padding: 0;

                              &.amount {
                                .price {
                                  padding-right: 0;
                                  font-weight: 700;

                                  &[class*="$0.00"] {
                                    font-size: 0;

                                    &::before {
                                      content: "FREE";
                                      padding: 0 3px;
                                      background: $red;
                                      color: white;
                                      font-size: 14px;
                                    }
                                  }
                                }
                              }
                            }

                            th {
                              padding: 0;

                              &.mark {
                                .label {
                                  color: $black;
                                  font-weight: 500;
                                }
                              }
                            }

                            td,
                            th {
                              padding-top: 5px;
                              font-weight: 500;
                            }

                            &.shipping {
                              th {
                                &.mark {
                                  .label {
                                    display: none;
                                  }

                                  .value {
                                    color: $black;
                                  }
                                }
                              }
                            }

                            &.sub {
                              td,
                              th {
                                padding-top: 15px;
                              }
                            }

                            &.totals-tax {
                              order: 99;
                              margin-top: -10px;

                              th {
                                display: none;
                              }

                              td {
                                padding-top: 0;
                                font-size: 12px;
                                font-weight: 500;

                                .price {
                                  font-size: 12px;
                                  font-weight: 500;
                                }

                                &::before {
                                  content: "Incl";
                                }

                                &::after {
                                  content: "GST";
                                }
                              }
                            }

                            &.grand {
                              td,
                              th {
                                padding-top: 5px;
                                padding-bottom: 0;

                                &.mark {
                                  strong {
                                    font-size: 14px;
                                    font-weight: 700;
                                  }
                                }

                                &.amount {
                                  .price {
                                    display: flex;
                                    align-items: center;
                                    font-size: 21px;
                                    font-weight: 800;

                                    &::before {
                                      content: "AUD";
                                      margin-right: 5px;
                                      font-size: 12px;
                                      font-weight: 500;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        > .title {
                          padding-bottom: 0;
                          border-bottom: 0;
                          font-size: 21px;
                          font-weight: 700;

                          @include mobile {
                            border: 0;
                          }
                        }

                        .block {
                          position: relative;

                          .back-to-cart {
                            position: absolute;
                            top: 6px;
                            right: 0;
                            padding: 3px 5px;
                            border: 1px solid transparent;
                            border-radius: 5px;
                            color: $black;
                            font-size: 14px;
                            font-weight: 700;
                            cursor: pointer;
                            pointer-events: all;

                            &:hover {
                              border-color: $grey;
                              text-decoration: none;
                            }

                            &::after {
                              content: "\e90b";
                              margin-left: 5px;
                              font-family: icomoon;
                              font-size: 12px;
                            }
                          }

                          .title {
                            display: flex;
                            padding-right: 14px;
                            cursor: initial;
                            pointer-events: none;

                            strong {
                              flex: 1;
                              font-size: 14px;
                              font-weight: 500;
                              line-height: 20px;
                            }

                            .estimated-block {
                              .estimated-price {
                                font-weight: 700;
                              }
                            }

                            &::after {
                              display: none;
                            }
                          }

                          .content {
                            display: block !important;
                            margin-top: 15px;

                            .minicart-items-wrapper {
                              max-height: calc(100vh - 450px);
                              padding-top: 0;

                              @include mobile {
                                max-height: 100%;
                              }

                              .minicart-items {
                                .product-item {
                                  padding: 10px 0;
                                  border: none;

                                  .product {
                                    .product-image-container {
                                      width: 70px !important;
                                      height: auto !important;

                                      .product-image-wrapper {
                                        aspect-ratio: 1/1;
                                        padding: 5px !important;

                                        &::before {
                                          content: "";
                                          position: absolute;
                                          top: 0;
                                          right: 0;
                                          width: 100%;
                                          height: 100%;
                                          border-radius: 5px;
                                          background: $lightgrey;
                                          aspect-ratio: 1/1;
                                        }

                                        img {
                                          mix-blend-mode: darken;
                                        }
                                      }
                                    }

                                    .product-item-details {
                                      .product-item-inner {
                                        .product-item-name-block {
                                          padding-right: 15px;

                                          .product-item-name {
                                            display: -webkit-box;
                                            overflow: hidden;
                                            color: $black;
                                            font-size: 12px;
                                            font-weight: 500;
                                            line-height: 17px;
                                            text-align: left;
                                            text-overflow: ellipsis;
                                            white-space: normal;
                                            hyphens: auto;
                                            overflow-wrap: anywhere;
                                            -webkit-box-orient: vertical;
                                            -webkit-line-clamp: 2;
                                          }

                                          .details-qty {
                                            color: $darkgrey;
                                            font-size: 12px;
                                          }
                                        }

                                        .subtotal {
                                          min-width: 60px;
                                          font-weight: 700;

                                          .old-price {
                                            color: $red;
                                            font-size: 14px;
                                            font-weight: 700;
                                          }

                                          .cart-price {
                                            .price {
                                              font-size: 14px;
                                              font-weight: 700;
                                            }
                                          }
                                        }
                                      }

                                      .product.options {
                                        .toggle {
                                          display: none;
                                        }

                                        .item-options {
                                          display: grid;
                                          grid-template-columns: 1fr 1fr;
                                          width: fit-content;
                                          font-size: 12px;

                                          &::before {
                                            display: none;
                                          }
                                        }
                                      }
                                    }

                                    .message {
                                      display: flex;
                                      width: fit-content;
                                      margin-top: -30px;
                                      margin-left: 88px;
                                      margin-left: 140px;
                                      padding: 5px 10px 5px 30px;
                                      font-size: 14px;

                                      > * {
                                        &::before {
                                          top: 13px;
                                          left: -3px;
                                          font-size: 15px;
                                        }
                                      }
                                    }

                                    .preorder_note {
                                      color: $red;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        #timer-default {
                          display: flex;
                          position: relative;
                          flex-wrap: wrap;
                          width: calc(100%);
                          max-width: none;
                          margin: 0;
                          padding: 20px 30px;
                          background: $lightblue;

                          @include media(mobile, tabletportrait, tabletlandscape) {
                            width: calc(100%);
                            margin-right: 0;
                            margin-left: 0;
                            padding: 20px;
                          }

                          .amcountdown-text {
                            flex: 1;
                            width: 100%;
                            min-width: 210px;
                            margin-bottom: 0;
                            padding-right: 20px;

                            @include media(mobile, tabletportrait, tabletlandscape) {
                              padding-right: 0;
                            }

                            &.-after {
                              display: none;
                              margin-top: 0;
                              margin-bottom: 0;
                            }

                            h1,
                            h2,
                            h3 {
                              margin: 0 0 5px;
                              color: $blue;
                              font-size: 16px;
                              font-weight: 700;
                            }

                            p {
                              margin-bottom: 0;
                              color: $black;
                              font-size: 11px;
                              font-weight: 300;
                              line-height: 13px;
                            }
                          }

                          .amcountdown-content {
                            flex: 1;
                            align-items: center;
                            justify-content: flex-start;
                            width: 50%;
                            min-width: 210px;
                            margin-bottom: 0;

                            .amcountdown-link {
                              display: none;
                            }

                            .amcountdown-list {
                              position: relative;
                              margin: 20px 0 0;

                              @include media(mobile, tabletportrait, tabletlandscape) {
                                margin-top: 30px;
                              }

                              &::before {
                                content: "Ends in:";
                                position: absolute;
                                top: -20px;
                                width: 100%;
                                font-size: 11px;
                                font-weight: 700;
                              }

                              .amcountdown-wrap {
                                .amcountdown-item {
                                  margin-bottom: 0;
                                  padding: 0;
                                  background: white;

                                  span {
                                    padding: 0 0 3px;
                                    color: $black;
                                    line-height: 11px;

                                    &.amcountdown-number {
                                      padding: 3px 0 0;
                                      font-weight: 800;
                                      line-height: 18px;

                                      &::after {
                                        display: none;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      .opc-block-shipping-information {
                        display: none;
                        padding: 0 0 0 30px;

                        @include mobile {
                          padding: 0 15px;
                        }

                        .shipping-information {
                          .ship-via,
                          .ship-to {
                            .shipping-information-title {
                              >span {
                                font-size: 21px;
                                font-weight: 700;
                              }

                              .action-edit {
                                height: 30px;
                                padding: 0 5px;
                                border: 1px solid transparent;
                                border-radius: 5px;
                                color: #121011;
                                font-size: 14px;
                                font-weight: 700;
                                cursor: pointer;
                                pointer-events: all;

                                &:hover {
                                  border-color: $grey;
                                  text-decoration: none;
                                }

                                >span {
                                  position: relative;
                                  width: auto;
                                  height: auto;
                                  margin: auto;
                                  overflow: visible;
                                  clip: unset;
                                }

                                &::after {
                                  content: "\e90b";
                                  margin-left: 0;
                                  font-family: icomoon;
                                  font-size: 12px;
                                }

                                &::before {
                                  display: none;
                                }
                              }
                            }

                            .shipping-information-content {
                              line-height: 19px;

                              > span {
                                line-height: 19px;
                              }

                              a {
                                color: $black;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .modal-custom-overlay {
                @include mobile {
                  display: none !important;
                }
              }
            }

            .ampromo-items-add {
              position: relative;
              width: calc(50% - 30px);
              max-width: 600px;
              margin-top: 15px;
              margin-bottom: 0;
              padding: 15px 30px;
              border: 1px solid $green;
              background: $lightgreen;
              font-size: 15px;
              font-weight: 500;
              text-align: left;

              &::before {
                display: none;
              }

              @include mobile {
                width: 100%;
                margin-right: auto;
                margin-left: auto;
                padding: 15px;
              }
            }
          }
        }
      }

      .ampromo-overlay {
        .ampromo-popup-wrapper {
          top: 50%;
          width: auto;
          margin: 0 auto;
          padding: 15px;
          transform: translateY(-50%);
          background: $lightgrey;

          h2 {
            margin-top: 0;
            font-size: 21px;
          }

          .ampromo-popup-container {
            .ampromo-carousel-product {
              .ampromo-gallery {
                margin: 0;
                padding: 0;

                .ampromo-item {
                  background: white;

                  &:hover {
                    border: 1px solid $grey;
                    background: white;
                  }

                  .ampromo-items-form {
                    background: transparent;

                    .ampromo-product-select {
                      position: absolute;
                      top: 5px;
                      right: 5px;

                      input {
                        border: none;
                      }
                    }

                    .ampromo-item-title {
                      .ampromo-title {
                        max-height: 30px;
                        margin: 0;
                        overflow: hidden;

                        a {
                          color: $black;
                          font-weight: 500;
                        }
                      }
                    }

                    .ampromo-options {
                      display: none;
                    }

                    .product-info-price {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;

                      .price-base-price {
                        .price {
                          margin: 0;
                          margin-left: 10px;
                          color: $red;
                          font-size: 16px;
                          font-weight: 700;
                          line-height: 16px;
                          text-decoration: line-through;
                        }
                      }

                      .price-new-price {
                        .price-wrapper {
                          font-size: 16px;
                          line-height: 100%;

                          .price {
                            display: block;
                            height: 24px;
                            font-size: 0;
                            font-weight: 800;
                            line-height: 24px;

                            &::before {
                              content: "FREE";
                              font-size: 21px;
                              line-height: 24px;
                            }
                          }
                        }
                      }
                    }

                    .ampromo-item-qty-input {
                      .ampromo-qty {
                        max-width: 30px;
                        margin-right: 5px;
                      }
                    }

                    .ampromo-item-buttons {
                      .action {
                        &.tocart {
                          position: relative;
                          width: 100%;
                          height: 50px;
                          margin: 0;
                          overflow: hidden;
                          transition: 300ms ease-in-out all;
                          border: 0;
                          border-radius: 0;
                          border-radius: 3px;
                          background: $brightblue;
                          color: white;
                          font-size: 14px;
                          font-weight: 700;
                          text-transform: none;

                          &::after {
                            content: "\e901";
                            display: flex;
                            position: absolute;
                            top: 0;
                            right: 0;
                            align-items: center;
                            justify-content: center;
                            width: 50px;
                            height: 100%;
                            transform: translateX(50px);
                            transition: 300ms ease-in-out transform;
                            background: #121011;
                            font-family: icomoon;
                            font-size: 24px;
                            text-indent: 0;
                          }

                          &:hover {
                            &::after {
                              transform: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                .slick-arrow {
                  display: none !important;
                }

                .slick-dots {
                  margin-top: 10px;

                  li {
                    button {
                      &::before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        border-radius: 8px;
                        opacity: 0.3;
                        background: $black;
                      }
                    }

                    &.slick-active {
                      button {
                        &::before {
                          opacity: 1;
                        }
                      }
                    }
                  }
                }

                .slick-list {
                  .slick-track {
                    display: flex;
                    margin: 0;
                  }
                }
              }

              .ampromo-item-buttons {
                .ampromo-button {
                  position: relative;
                  width: 100%;
                  max-width: 240px;
                  height: 50px;
                  margin: 0;
                  margin-top: 10px;
                  overflow: hidden;
                  transition: 300ms ease-in-out all;
                  border: 0;
                  border-radius: 0;
                  border-radius: 3px;
                  background: $brightblue;
                  color: white;
                  font-size: 14px;
                  font-weight: 700;
                  text-transform: none;

                  &::after {
                    content: "\e901";
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 100%;
                    transform: translateX(50px);
                    transition: 300ms ease-in-out transform;
                    background: #121011;
                    font-family: icomoon;
                    font-size: 24px;
                    text-indent: 0;
                  }

                  &:hover {
                    &::after {
                      transform: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal-popup {
    &._show {
      z-index: 99999 !important;
    }

    &.modal-slide {
      left: 0;

      .modal-inner-wrap {
        position: fixed;
        max-height: calc(100% - 20px);
      }
    }

    .modal-inner-wrap {
      position: fixed;
      height: calc(100% - 20px);
      min-height: 0;

      .modal-header {
        h1 {
          font-weight: 700;
        }
      }

      .modal-content {
        #opc-new-shipping-address {
          .form-shipping-address {
            #shipping-new-address-form {
              .field {
                > label {
                  display: none;
                }

                input[type="checkbox"] {
                  ~ label {
                    display: block;
                  }
                }

                &.address {
                  display: none;
                }

                &[name="shippingAddress.manual"] {
                  display: none;

                  select {
                    + label {
                      display: none;
                    }
                  }
                }

                &.hide:not([name="shippingAddress.country_id"]) {
                  display: block !important;
                }

                &.street {
                  legend {
                    display: none;
                  }
                }

                .field-error,
                .mage-error {
                  padding: 5px 0;
                  background: transparent;
                  color: $red;
                }

                .input-text,
                .select {
                  min-height: 50px;
                  padding-top: 10px;
                  padding-left: 15px;
                  border-radius: 5px;

                  ~ label {
                    display: block;
                    position: absolute;
                    top: 15px;
                    left: 16px;
                    height: auto;
                    clip: auto;
                    transition: 300ms ease-in-out all;
                    color: $darkgrey;
                    font-size: 14px;
                    font-weight: 400;
                    pointer-events: none;
                  }

                  &:not(:placeholder-shown) {
                    border-color: $green;
                  }

                  &:focus ~ label,
                  &:not(:placeholder-shown) ~ label {
                    top: 8px;
                    font-size: 11px;
                  }
                }
              }
            }
          }
        }

        form {
          .field:not(.qty):not(.search).choice {
            &::before {
              display: none;
            }
          }
        }

        #opc-store-selector-popup {
          #store-selector-popup {
            .block-search {
              position: relative;
              top: auto;
              left: auto;
              max-width: none;
              margin-bottom: 0;
              margin-bottom: 20px;
              padding-bottom: 0;
              transform: none;

              .control {
                margin: 0;
                padding: 0 0 15px;
                border: 0;
              }

              #store-selector-search-input {
                top: 0;
                left: 0;
                height: 40px;
                margin: 0;
                box-shadow: none;
              }
            }

            .store-selector-popup-empty-query {
              margin-top: 10px;
            }

            .store-selector-popup-table {
              tr.location {
                display: flex;
                flex-direction: column;

                td {
                  padding-right: 0;
                  padding-left: 0;

                  .location-description {
                    ul {
                      margin-bottom: 0;
                      padding-left: 0;
                      list-style: none;

                      li {
                        font-weight: 500;

                        &::before {
                          content: "\ea10";
                          margin-right: 5px;
                          color: $green;
                          font-family: icomoon;
                        }

                        strong {
                          font-family: Nunito, sans-serif !important;
                          font-size: 14px;
                          font-weight: 500;
                        }
                      }
                    }
                  }

                  .select-location,
                  .location-selected {
                    width: 100%;

                    button {
                      width: 100%;
                      background: $brightblue;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        .primary {
          order: 1;
          border: $orange;
          background: $orange;
        }
      }
    }
  }
}
