* {
  box-sizing: border-box;
}

body {
  width: 100%;
  padding-top: 120px;
  transition: 300ms ease-in-out padding;
  background-color: $lightgrey;
  font-family: Nunito, sans-serif;
  overscroll-behavior: none;

  @include mobile {
    padding-top: 170px;
  }

  &.cms-page-view {
    .page-main {
      max-width: 1920px;
    }
  }

  .block-static-block.widget,
  .block-cms-link.widget {
    margin-bottom: 0;
  }

  a {
    color: $brightblue;

    &.action.primary,
    &.action-primary,
    &.amshopby-button,
    &.am-filter-go {
      font-family: Nunito, sans-serif;
    }
  }

  .action.primary,
  .action-primary,
  .amshopby-button,
  .am-filter-go {
    font-family: Nunito, sans-serif;
  }
}

[data-content-type="row"][data-appearance="contained"] {
  max-width: 1920px;
}

.recommendations {
  .block-title {
    padding-left: 15px;
    font-size: 24px;
  }
}

[data-content-type="row"][data-appearance="full-width"] > .row-full-width-inner {
  max-width: 1920px;
}

.loading-mask {
  backdrop-filter: blur(3px);

  .loader > img {
    width: 70px;
  }
}

.amsl-popup-overlay {
  .amsl-popup-block {
    background: $lightgrey;

    .amsl-login-success {
      color: $black;
    }

    .amsl-close {
      &:hover,
      &:active {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMSAxMW0wLTExTDEgMTIiIHN0cm9rZT0iI0IyQjJCMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==") center/15px no-repeat;
      }

      &:focus {
        outline: 0;
      }
    }

    .password-strength-meter {
      background: transparent;
    }

    .amsl-tabs-wrapper {
      .amsl-content {
        .amsl-form {
          max-width: 100%;
          margin-bottom: 20px;

          .fieldset {
            .field-name-firstname {
              display: inline-block;
            }

            .field-name-firstname,
            .field-name-lastname {
              width: 48%;
            }

            > .field.choice {
              &::before {
                display: none;
              }
            }

            .input-text {
              border: 1px solid $grey;
              background: white;
            }

            label {
              color: $black;
            }

            .field-tooltip-content {
              top: 40px;
              right: -10px;
              left: auto;

              &::before {
                top: 0;
                right: 10px;
                left: auto;
                width: 0;
                height: 0;
                margin-top: -21px;
                border: 10px solid transparent;
                border-bottom-color: #999;
              }

              &::after {
                top: 1px;
                right: 10px;
                left: auto;
                width: 0;
                height: 0;
                margin-top: -21px;
                border: 10px solid transparent;
                border-bottom-color: #f4f4f4;
              }
            }
          }
        }

        .amsl-separator {
          display: none;
        }

        .amsl-social-wrapper {
          margin: 0 40px 40px;

          @include mobile {
            margin: 0 20px 20px;
          }

          .amsl-social-login {
            min-height: 0;

            .amsl-title {
              position: relative;
              transform: none;
              background: none;
            }

            .amsl-buttons {
              display: flex;
              padding: 0;
              gap: 20px;

              .amsl-button-wrapper {
                flex: 1;
                min-width: 230px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

.message.info,
.message.notice {
  background: $beige;
  color: $black;
  font-size: 16px;

  > *:first-child {
    &::before {
      content: "\e96e";
      color: $black;
      font-family: icomoon;
      font-size: 18px;
    }
  }
}

.page.messages {
  position: fixed;
  z-index: 997;
  bottom: 100px;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 400px;
  transform: translate(-50%, 300%);
  transition: 300ms ease-in-out transform;

  &.show {
    transform: translate(-50%, 0);
  }

  .message {
    padding: 20px 20px 20px 50px;
    font-size: 14px;

    > *:first-child {
      &::before {
        top: 50%;
        left: 10px;
        margin-top: 0;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
  }

  .message.info {
    background: $beige;
    color: $black;
    font-size: 16px;

    > *:first-child {
      &::before {
        content: "\e96e";
        color: $black;
        font-family: icomoon;
      }
    }
  }

  .message.success {
    background: $lightblue;
    color: $blue;

    >*:first-child::before {
      content: "\ea10";
      color: $blue;
      font-family: icomoon;
    }

    a {
      color: $brightblue;
    }
  }
}

.message.error {
  background: $red;
  color: white;

  >*:first-child::before {
    content: "\ea0f";
    color: white;
    font-family: icomoon;
    font-size: 16px;
  }

  a {
    color: $black;
  }
}

/* Button */

button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: none;
  background: $brightblue;
  color: white;
  font-family: Nunito, sans-serif;

  &:hover {
    border: none;
    background: $blue;
    color: white;
  }
}

div.pagebuilder-button-secondary,
a.pagebuilder-button-secondary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: none;
  background: $orange;
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border: none;
    background: $red;
    color: white;
  }
}

div.pagebuilder-button-primary,
a.pagebuilder-button-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: none;
  background: $brightblue;
  color: white;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    border: none;
    background: $blue;
    color: white;
  }
}

.amsl-button-wrapper.with-label.-rectangular {
  .amsl-button {
    border-color: transparent;
    font-weight: 700;
  }
}

/* Pagination */
.pages {
  .items {
    .item {
      margin-right: 4px;

      .page {
        span {
          &:not(.label) {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            height: 30px;
            padding: 0 5px;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 500;

            &:hover {
            }
          }
        }
      }

      &:not(.current) {
        .page {
          padding: 0;
          border: 1px solid $grey;
          border-radius: 3px;
          background: white;
          color: $darkgrey;

          span {
            &:not(.label) {
              &:hover {
                color: $blue;
              }
            }
          }
        }
      }

      &.current {
        .page {
          padding: 0;
          border: 1px solid transparent;
          border-radius: 3px;
          background: $blue;
          color: white;

          span {
            &:not(.label) {

            }
          }
        }
      }

      &.pages-item-next {
        .action {
          margin-left: 0;
          border: 1px solid $grey;
          border-radius: 3px;
          background: white;

          &::before {
            color: $grey;
          }

          &:hover {
            &::before {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

#instant-search-pagination-container {
  .ais-Pagination {
    ul.ais-Pagination-list {
      display: flex;
      gap: 10px;
      margin: 30px 0;

      .ais-Pagination-item {
        &.ais-Pagination-item--previousPage,
        &.ais-Pagination-item--nextPage {
          padding: 0;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-left: 0;
            padding: 0;
            border: none;
            border-radius: 20px;
            background: $lightgrey;
            font-size: 0;

            &::before {
              content: "\e906";
              transform: rotate(90deg);
              color: $black;
              font-family: icomoon;
              font-size: 7px;
            }
          }
        }

        &.ais-Pagination-item--nextPage {
          a {
            &::before {
              transform: rotate(-90deg);
            }
          }
        }

        &.ais-Pagination-item--page {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin-left: 0;
          padding: 0;
          border: none;
          border-radius: 20px;
          background: $lightgrey;

          a {
            display: block;
            padding: 10px;
            color: $darkgrey;
          }

          &:hover {
            border-color: $darkgrey;

            a {
              color: $black;
              text-decoration: none;
            }
          }
        }

        &.ais-Pagination-item--selected {
          border-color: transparent;
          background-color: $blue;

          a {
            color: white;
          }

          &:hover {
            border-color: transparent;

            a {
              color: white;
            }
          }
        }
      }
    }
  }
}

/* Breadcumbs */

.breadcrumbs {
  max-width: 1920px;
  margin-top: 20px;

  @include mobile {
    margin: 10px 0;
  }
}

/* Inputs */

form {
  &.address {
    margin: 0;
    padding: 0;
  }

  input {
    + label {
      display: none;
    }

    &[type="text"],
    &[type="email"],
    &[type="password"],
    &.input-text {
      &:not(#search) {
        max-width: 520px;
        height: 40px;
        border-radius: 0;
        line-height: 40px;
      }
    }

    &:not([disabled]) {
      &:not([type="checkbox"]) {
        &:focus {
          border: 1px solid $green;
          box-shadow: none;
        }

        &.mage-error {
          border: 1px solid $red;
          color: $black;

          &:focus {
            border: 1px solid $red;
            box-shadow: none;
          }
        }
      }
    }
  }

  .field:not(.qty):not(.search) {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 0;

    &:is(fieldset) {
      padding: 0;
    }

    div.mage-error,
    div.field-error {
      margin-top: 0;
      padding: 10px;
      background: $red;
      color: white;
    }

    .message.warning {
      margin-top: 0;
      padding: 10px;

      > *:first-child::before {
        content: "";
      }
    }

    &:not(.choice) {
      .control {
        width: 100%;
      }

      .label {
        width: 100%;
        padding: 0 0 5px;
        font-size: 11px;
        font-weight: 700;
        text-align: left;

        &::after {
          margin-left: 2px;
          color: $red;
          font-size: 11px;
        }

        &.active {

        }
      }
    }

    .control {
      width: 100%;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &.input-text {
        &:not(#search) {
          max-width: 520px;
          height: 40px;
          border-radius: 0;
          line-height: 40px;
        }
      }

      &:not([disabled]) {
        &:not([type="checkbox"]) {
          &:focus {
            border: 1px solid $green;
            box-shadow: none;
          }

          &.mage-error {
            border: 1px solid $red;
            color: $black;

            &:focus {
              border: 1px solid $red;
              box-shadow: none;
            }
          }
        }
      }
    }

    textarea {
      max-width: 520px;
      border-radius: 0;

      &:not([disabled]) {
        &:not([type="checkbox"]) {
          &:focus {
            border: 1px solid $green;
            box-shadow: none;
          }

          &.mage-error {
            border: 1px solid $red;
            color: $black;

            &:focus {
              border: 1px solid $red;
              box-shadow: none;
            }
          }
        }
      }
    }

    select {
      max-width: 520px;
      height: 40px;
      border-radius: 0;
      color: $black;
      cursor: pointer;
      appearance: none;

      &:not([disabled]) {
        &:not([type="checkbox"]) {
          &:focus {
            border: 1px solid $green;
            box-shadow: none;
          }

          &.mage-error {
            border: 1px solid $red;
            color: $black;

            &:focus {
              border: 1px solid $red;
              box-shadow: none;
            }
          }
        }
      }
    }

    &.choice {
      display: flex;
      place-items: center;

      input[type="checkbox"] {
        margin-right: 5px;
      }

      label {
        span {
          line-height: 14px;
        }
      }
    }
  }
}

/* Radios */
input[type="radio"] {
  display: inline-grid;
  width: 26px;
  height: 26px;
  margin: 0;
  transform: translateY(-0.075em);
  border: 1px solid $grey;
  border-radius: 50%;
  background-color: #fff;
  color: $blue;
  font: inherit;
  appearance: none;
  place-content: center;

  &:not([disabled]) {
    &:focus {
      box-shadow: none;
    }
  }
}

input[type="radio"]::before {
  content: "\ea10";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  border-radius: 50%;
  background: $green;
  box-shadow: none;
  color: white;
  font-family: icomoon;
  font-size: 12px;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

/* Checkboxes */
input[type="checkbox"] {
  display: inline-grid;
  width: 26px;
  height: 26px;
  margin: 0;
  transform: translateY(-0.075em);
  border: 1px solid $grey;
  background-color: #fff;
  color: $blue;
  font: inherit;
  appearance: none;
  place-content: center;

  &:not([disabled]) {
    &:focus {
      box-shadow: none;
    }
  }
}

input[type="checkbox"]::before {
  content: "\ea10";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  color: #7ccea3;
  font-family: icomoon;
  font-size: 18px;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.sidebar {
  .widget.block {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 5px;
        list-style: none;

        a {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
}

.braintree-apple-pay-button {
  width: 100%;
  height: 50px;
  margin-top: 0;
  margin-bottom: 0;
}
