body {
  &.account {
    background: $lightgrey;

    #store-notification {
      display: none;
    }

    .page-main {
      max-width: 100%;
      min-height: 760px;
      margin-top: 0;
      padding: 0;

      @include mobile {
        min-height: 0;
      }

      form {
        .fieldset {
          .legend {
            margin-left: 0;

            span {
              font-size: 21px;
              font-weight: 700;
            }
          }

          > .field.choice {
            &::before {
              display: none;
            }
          }
        }

        .actions-toolbar {
          margin-left: 0;

          .primary {
            .action {
              display: flex;
              width: 100%;
              max-width: 160px;
              height: 40px;
              border: 0;
              font-size: 14px;

              @include mobile {
                max-width: none;
              }

              &.share {
                background: $green;
                color: $blue;
              }

              &.redeem {
                background: $green;
                color: $blue;
              }
            }
          }
        }

        &#form-validate,
        &#giftcard-form {
          max-width: 500px;
          padding: 20px;
          border: 1px solid $grey;
          background: white;
        }
      }

      .columns {
        display: flex;
        overflow: hidden;

        .sidebar-additional {
          display: none;
        }

        .sidebar-main {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          max-width: 350px;
          height: 100%;
          min-height: 760px;
          padding-top: 0;
          padding-right: 0;
          background: $blue;

          &::after {
            content: "";
            position: absolute;
            z-index: -1;
            top: 100%;
            left: 0;
            width: 100%;
            height: 3000px;
            background: $blue;

            @include mobile {
              display: none;
            }
          }

          @include mobile {
            position: absolute;
            top: 0;
            max-width: none;
            height: auto;
            min-height: auto;
            padding-top: 0;

            .block-collapsible-nav {
              top: 0;

              .title {
                display: flex;
                align-items: center;
                height: 50px;
                padding: 0;
                border: 0;

                &::after {
                  top: 6px;
                }

                strong {
                  margin: 0;
                }
              }
            }
          }

          @include laptop {
            max-width: 300px;
          }

          @include tabletportrait {
            max-width: 250px;
          }

          @include tabletlandscape {
            max-width: 250px;
          }

          .block {
            @include mobile {
              background: $blue;
            }

            .title {
              padding-left: 15px;

              strong {
                color: white;
              }

              &::after {
                color: white;
              }
            }

            .content {
              background: transparent;

              @include mobile {
                padding-top: 0;
                background: $blue;
              }

              ul.nav {
                li.item {
                  > a,
                  >strong {
                    padding: 15px;
                    border-left: none;
                    color: white;
                    font-weight: 700;
                  }

                  &.current {
                    background: $lightgrey;

                    a {
                      color: $blue;
                    }

                    > strong {
                      border-color: transparent;
                      color: $blue;
                      font-weight: 700;
                    }
                  }

                  span {
                    &.delimiter {
                      border-color: white;
                    }
                  }

                  &:hover {
                    a {
                      color: $blue;
                    }
                  }
                }
              }
            }
          }
        }

        .column.main {
          width: calc(100% - 350px);
          max-width: 1920px;
          padding-top: 40px;
          padding-right: 15px;

          @include mobile {
            padding: 70px 15px 0;
          }

          @include laptop {
            width: calc(100% - 300px);
          }

          @include tabletportrait {
            width: calc(100% - 250px);
          }

          @include tabletlandscape {
            width: calc(100% - 250px);
          }

          .order-details-items {
            border-bottom: none;

            .order-title {
              display: none;
            }
          }

          .order-links {
            border-bottom: none;

            .item {
              margin-bottom: 10px;
              font-size: 21px;
            }
          }

          .page-title-wrapper {
            h1.page-title {
              span {

              }
            }
          }

          .block {
            .block-title {
              margin-top: 10px;

              strong {
                font-size: 21px;
                font-weight: 700;
              }
            }

            .block-content {
              display: flex;
              justify-content: flex-start;

              .form-addbysku {
                display: flex;
                justify-content: flex-start;
              }

              @include mobile {
                flex-direction: column;
              }

              .box {
                box-sizing: border-box;
                max-width: 480px;
                margin-right: 40px;
                padding: 20px;
                border: 1px solid $grey;
                background: white;

                @include mobile {
                  margin-right: 0;
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            &.block-reviews-dashboard {
              .block-content {
                ol.items {
                  li.item {
                    .product-name {

                    }

                    .rating-summary {
                      .label {

                      }

                      .rating-result {
                        letter-spacing: -9px;

                        span {
                          &::before {
                            color: $orange;
                            letter-spacing: -9px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .wishlist-toolbar {
            .pager {
              .toolbar-amount {
                display: none;
              }

              .limiter {
                float: left;
              }
            }
          }
        }
      }
    }

    .form-wishlist-items {
      .actions-toolbar {
        margin-top: 40px;

        .primary {
          display: flex;
          width: 100%;

          @include mobile {
            flex-direction: column;
          }

          .action {
            &.tocart {
              background: $orange;
            }
          }
        }
      }
    }

    .product-items {
      li.product-item {
        .product-item-info {
          @include mobile {
            position: relative;
            flex-direction: column;
            padding-left: 140px !important;
          }

          .product-item-photo {
            position: relative;
            line-height: 1;
            aspect-ratio: 1/1;

            @include mobile {
              position: absolute;
              left: 10px;
            }

            .product-image-wrapper {
              padding: 0;

              .product-image-photo {
                position: relative;
                width: 100%;
                height: auto;
                aspect-ratio: 1;
                object-fit: contain;
              }
            }
          }

          .product-item-name {
            font-size: 14px;

            @include mobile {
              width: 100%;
              margin-left: 0;
            }
          }

          .product-reviews-summary {

          }

          .price-box {
            text-align: left;

            .price-label {
              display: none;
            }

            .special-price {
              display: inline;

              .price {
                color: $black;
                font-size: 24px;
                font-weight: 800;
              }
            }

            .old-price {
              display: inline;

              .price {
                color: $red;
                font-size: 18px;
                font-weight: 700;
                text-decoration: line-through;
              }
            }
          }

          .product-item-inner {
            .comment-box {

            }

            .product-item-actions {
              margin-top: 0;
            }

            .box-tocart {
              @include mobile {
                margin-left: 0;
              }

              .fieldset {
                margin-bottom: 0;
              }

              .qty {

              }

              .product-item-actions {
                margin-top: 0;

                .actions-primary {
                  display: flex;
                  position: absolute;
                  right: 0;
                  bottom: -50px;
                  width: 100%;
                  height: 50px;
                  transition: 300ms ease-in-out transform;
                  border-radius: none;

                  @include mobile {
                    position: relative;
                    bottom: auto;
                  }

                  button.tocart {
                    width: 100%;
                    height: 50px;
                    margin: 0;
                    transition: 300ms ease-in-out all;
                    border: 0;
                    border-radius: 0;
                    background: $orange;
                    color: white;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: none;
                  }
                }
              }
            }
          }

          @include mobile {
            display: block;
          }

          .price-box {
            margin-left: 0;
          }
        }

        &:hover {
          box-shadow: none;

          .product-item-info {
            margin-bottom: 0 !important;
            padding-bottom: 10px;

            .product-item-inner {
              .box-tocart {
                .product-item-actions {
                  .actions-primary {
                    transform: translateY(-50px);

                    @include mobile {
                      transform: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .rating-summary {
      left: 0;

      .rating-result {
        &::before {
          letter-spacing: -9px;
        }

        > span {
          &::before {
            color: $orange;
            letter-spacing: -9px;
          }
        }
      }
    }

    .table-wrapper {
      padding: 10px;
      border: 1px solid $grey;
      background: white;

      table {
        tr {
          td {
          }
        }
      }
    }
  }
}
