$blue: #002e54;
$lightblue: #caecfb;
$green: #7ccea3;
$lightgreen: #e2ede7;
$red: #e76449;
$orange: #ffa000;
$lightgrey: #f5f5f5;
$black: #121011;
$beige: #fff2c3;
$grey: #d4d4d4;
$darkgrey: #939393;
$brightblue: #1e6bc6;
$orangegradient: linear-gradient(0deg, rgb(233 165 51 / 100%) 0%, rgb(255 184 0 / 100%) 100%);
$color-array: (
  blue: $blue,
  lightblue: $lightblue,
  green: $green,
  lightgreen: $lightgreen,
  red: $red,
  orange: $orange,
  lightgrey: $lightgrey,
  black: $black,
  beige: $beige,
  grey: $grey,
  darkgrey: $darkgrey,
  brightblue: $brightblue,
  orangegradient: $orangegradient,
);

@each $color-name, $color-value in $color-array {
  .background-#{"" + $color-name} {
    background: $color-value;
  }
  .text-#{"" + $color-name} {
    color: $color-value;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
