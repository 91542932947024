#usp-bar {
  position: relative;
  width: 100%;
  height: 30px;
  background: $blue;

  ul {
    display: flex;
    justify-content: space-around;
    max-width: 1920px;
    height: 30px;
    margin: 0 auto;
    padding: 0;
    transition: opacity 0.5s ease;
    opacity: 0;

    &.slick-slider {
      opacity: 1;
    }

    li {
      display: inline-flex !important;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 5px 20px;
      transition: opacity 0.5s ease;
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 700;
      list-style: none;

      @include media(mobile, tabletportrait) {
        width: 100vw !important;
        text-align: center;
        text-wrap: nowrap;
        white-space: nowrap;
      }

      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      &.shipping {
        &::before {
          background-image: url("../images/static-blocks/usp-shipping.svg");
        }
      }

      &.pickup {
        &::before {
          background-image: url("../images/static-blocks/usp-pickup.svg");
        }
      }

      &.secure {
        &::before {
          background-image: url("../images/static-blocks/usp-secure.svg");
        }
      }

      &.slick-active {
        opacity: 1;
      }
    }
  }
}
