.highlighted-categories {
  margin-bottom: 50px;
  padding: 0 15px;

  .block-highlighted-categories {
    .pagebuilder-column-group {
      gap: 15px !important;

      .col {
        min-height: 0 !important;
        padding: 10px;
        background: white;

        @include mobile {
          flex: 1;
          flex-basis: auto;
          min-width: 45%;
        }

        h2 {
          @include mobile {
            margin: 10px 0 0;
            font-size: 18px;
          }
        }
      }
    }
  }
}
